import React,{ useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
export default function Logout() {

  useEffect(() => {
      window.location.href = "/login";
     localStorage.removeItem('merchantToken');
     localStorage.removeItem('group_id');

  }, []);

  return (<></> );
}



