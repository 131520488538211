/*
   @page Device manager
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import MUIDataTable from "mui-datatables";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import SelectMui from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { ArrowForward, ArrowBack, Padding } from "@mui/icons-material";
import { tr } from "date-fns/locale";
import { boxShadow } from "assets/jss/material-dashboard-react";

/*
  @const Page styles
*/
const styles = {
  root: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "#ffa726",
    },
  },
  rowPerPage: {
    minHeight: "40px",
    height: "40px",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

/*
  @function Device manager page logic
*/
const useStyles = makeStyles(styles);

export default function TableList() {
  /*
    variables start
  */
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { t } = useTranslation();
  const [tableData, setTableData] = useState(false);
  const [editKeyName, setEditKeyName] = useState("");
  const [editKeyLimit, setEditKeyLimit] = useState(0);
  const [editId, setEditId] = useState(0);

  const [keyName, setKeyName] = useState("");
  const [keyLimit, setKeyLimit] = useState(10);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [deleteKeyDialog, setKeyDeleteDailog] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [rowPerPage, setRowPerPage] = useState(15);
  const [updateKeyDialog, setUpdateKeyDailog] = React.useState(false);
  const [groupId, setGroupId] = useState([]);
  const [groupEditId, setGroupEditId] = useState([]);  
  const [userGroup, setUserGroup] = React.useState([]);
  const [adminEmail, setAdminEmail] = useState("");
  const [url, setURL] = useState("");
  const [seletedProcessor, setSeletedProcessor] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [showCreateBlock, setShowCreateBlock] = useState(false);
  const [nickname, setNickname] = useState("");

  var columns = [
    {
      name: "ID",
      label: t("lbl_id"),
      options: {
        sort: true,
        //hint: "Sort by ID",
      },
    },
    {
      name: "Nick Name",
      label: t("lbl_webhook_nickname"),

      options: {
        sort: true,
        //hint: "Sort by MID",
      },
    },
    {
      name: "URL",
      label: t("lbl_url"),
      options: {
        sort: true,
        //hint: "Sort by ID",
      },
    },
       
    {
      name: "Created at",
      label: t("lbl_created_at"),
      options: {
        filter: true,
        sort: true,
        //  hint: "Sort by Email",
      },
    },
    {
      name: "Edit",
      label: t("lbl_edit"),
      options: {
        filter: true,
        sort: false,
        //  hint: "Sort by Email",
      },
    },
    {
      name: "Delete",
      label: t("lbl_delete"),
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options = {
    selectableRows: "none",
    searchOpen: false,
    download: false,
    print: false,
    filter: false,
    searchAlwaysOpen: false,
  };

  const getWebhookList = () => {
    const data = {
      merchant_id: localStorage.getItem("parent_id"),
      location_id: localStorage.getItem("location_id"),
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    axios
      .post(window.apiUrl + "get_webhook_list", data, {
        headers: headers,
      })
      .then((response) => {
        var keyArr = [];

        setSeletedProcessor(response.data.settingData.processor);
        setSelectedGroup(response.data.settingData.group_id);

        response.data.webhookData.forEach((eachKey) => {         
        
          keyArr.push([
            eachKey.id,
            eachKey.nickname,
            eachKey.url,            
            eachKey.created_at,
            <button
              id="greenediticon"
              style={{
                backgroundColor: "#ffa726",
                color: "#FFFFFF",
                border: "none",
              }}
              onClick={() => {
                setShowCreateBlock(true);
                setEditId(eachKey.id);
                setNickname(eachKey.nickname);
                setURL(eachKey.url);                
                setRightList(eachKey.subscribe_webhook.split(","));
                setLeftList(
                  leftList.filter(
                    (item) =>
                      !eachKey.subscribe_webhook.split(",").includes(item)
                  )
                );
                
              }}
            >
              <EditIcon style={{ height: "20px" }}></EditIcon>
            </button>,
            <button
              id="reddeleteicon"
              style={{
                backgroundColor: "#FF9800",
                color: "#FFFFFF",
                border: "none",
              }}
              onClick={() => {
                setKeyDeleteDailog(true);
                setId(eachKey.id);
              }}
            >
              <DeleteForeverIcon></DeleteForeverIcon>
            </button>,
          ]);
        });
        setTableData(keyArr);
      })
      .catch((error) => {
        return error;
      });
  };

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  const handleDeleteId = () => {
    const data = {
      id: id,
    };

    setTableData(false);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    axios
      .post(window.apiUrl + "delete_webhook", data, {
        headers: headers,
      })
      .then((response) => {
        setId(0);
        setKeyDeleteDailog(false);
        setSuccess(true);
        setSuccessMsg(t("msg_delete_success_webhook"));
        getWebhookList();
      })
      .catch((error) => {
        getWebhookList();
        setKeyDeleteDailog(false);
        setError(true);
        setErrorMsg(t("msg_some_error_occur"));
        return error;
      });
  };

  useEffect(() => {
    setAdminEmail(localStorage.getItem("admin_email"));        
    if (!tableData) {
      getWebhookList();
    }
  }, []);

  const [leftList, setLeftList] = useState([
    "transaction.sale.success",
    "transaction.sale.failure",
    "transaction.sale.unknown",
    "transaction.void.success",
    "transaction.void.failure",
    "transaction.void.unknown",
    "transaction.refund.success",
    "transaction.refund.failure",
    "transaction.refund.unknown",
  ]);
  const [rightList, setRightList] = useState([]);

  const moveItemToRight = (item) => {
    setLeftList(leftList.filter((i) => i !== item));
    setRightList([...rightList, item]);
  };

  const moveItemToLeft = (item) => {
    setRightList(rightList.filter((i) => i !== item));
    setLeftList([...leftList, item]);
  };


 
    
  const saveWebhook = () => {
    if (!url) {
      setError(true);
      setErrorMsg(t("msg_please_enter_valid_url"));
      return;
    }   
  
    if (rightList.length === 0) {
      setError(true);
      setErrorMsg(t("lbl_select_event"));
      return;
    }    
    const data = {
      id: editId,
      merchant_id: localStorage.getItem("parent_id"),
      location_id: localStorage.getItem("location_id"),
      url: url,
      nickname: nickname,
      processor: seletedProcessor,
      groups: selectedGroup,
      subscribe_webhook: rightList.join(","),
    };
    console.log(data);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("merchantToken"),
    };
    axios
      .post(window.apiUrl + "save_webhook", data, {
        headers: headers,
      })
      .then((response) => {
        clearInterval();
        setShowCreateBlock(false);
        setSuccess(true);
        setSuccessMsg(t("lbl_save_webhook"));
        setTableData(false);
        getWebhookList();
      });
  };
  const clearInterval = () => {
    setEditId(0);
    setURL("");
    setSeletedProcessor("");
    setSelectedGroup("");
    setLeftList([
      "transaction.sale.success",
      "transaction.sale.failure",
      "transaction.sale.unknown",
      "transaction.void.success",
      "transaction.void.failure",
      "transaction.void.unknown",
      "transaction.refund.success",
      "transaction.refund.failure",
      "transaction.refund.unknown",
    ]);
    setRightList([]);
  };
  /*var permissionData = JSON.parse(localStorage.getItem("admin_permission"));
  if (permissionData.access_api_key == "0") {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>
                {t("lbl_webhook")}
              </h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                      <div>{t("msg_you_do_not_have_permission")}</div>
                      <div>{t("msg_please_contact_an_administrator")}</div>
                      <div>{t("msg_thank_you")}</div>
                    </h3>
                  </center>
                  <div id="transitInfo"></div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  } */
  if (!tableData) {
    //Check if device data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>
                {t("lbl_webhook")}
              </h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t("msg_loading")}</h3>
                <CircularProgress id="loginProgress"></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
  return (
    <GridContainer>
      {/* Clear Device  data popup start */}
      <GridItem xs={12} sm={12} md={12}>
        {/* 
            Display error message
          */}
        <Snackbar
          id="popuperror"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={error}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            style={{ whiteSpace: "pre-line" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
            Display success message
          */}
        <Snackbar
          id="popupsuccess"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={sucess}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Dialog
          open={deleteKeyDialog}
          keepMounted
          onClose={deleteKeyDialog}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {t("msg_delete_webhook")}
          </DialogTitle>
          <DialogActions>
            <Button
              id="btnGreyCancel"
              onClick={() => {
                setKeyDeleteDailog(false);
                setId(0);
              }}
              color="primary"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              {t("lbl_no")}
            </Button>
            <Button
              id="btnRedDelete"
              onClick={() => {
                handleDeleteId();
              }}
              color="secondery"
              style={{ backgroundColor: "#ffa726", color: "#FFFFFF" }}
            >
              {t("lbl_yes")}
            </Button>
          </DialogActions>
        </Dialog>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t("lbl_webhook")}</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            {(!showCreateBlock)? <div>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={2}
                  style={{ marginBottom: "20px" }}
                >
                  <center>
                    <FormControl sx={{ width: "100%" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        id="createBlueBtn"
                        style={{
                          color: "#FFFFFF",
                          width: "100%",
                          height: "55px",
                        }}
                        className={classes.button}
                        onClick={(e) => {
                          setShowCreateBlock(true);
                        }}
                      >
                        {t("lbl_create")}
                      </Button>
                    </FormControl>
                  </center>
                </GridItem>
              </GridContainer>
            </div>:""}
           
            {showCreateBlock ? (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl id="transToFrom" sx={{ width: "100%" }}>
                      {/* Amount to textfield */}
                      <TextField
                        id="outlined-basic"
                        className="transAmountToLabel"
                        variant="outlined"
                        value={nickname}
                        onChange={(e) => {
                          setNickname(e.target.value);
                        }}
                        label={t("lbl_webhook_nickname")}
                        style={{ width: "100%" }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl id="transToFrom" sx={{ width: "100%" }}>
                      {/* Amount to textfield */}
                      <TextField
                        id="outlined-basic"
                        className="transAmountToLabel"
                        variant="outlined"
                        value={url}
                        onChange={(e) => {
                          setURL(e.target.value);
                        }}
                        label={t("lbl_enter_url")}
                        style={{ width: "100%" }}
                      />
                    </FormControl>
                  </GridItem>
                 
                </GridContainer>

                <Box sx={{ display: "flex", gap: 2, marginTop: "20px" }}>
                  <Box
                    id="availableEventBox"
                    sx={{
                      width: "50%",
                      minHeight: "100px",
                      border: "2px solid #EEE",
                      borderRadius: "5px",
                      boxShadow:"#DADADA 3px 3px 6px"
                    }}
                  >
                    <Typography
                      style={{
                        background: "#EEE",
                        borderTopLeftRadius: "1px",
                        borderTopRightRadius: "1px",
                        Padding: "20px",
                      }}
                      variant="h6"
                    >
                      {t("lbl_available_event")}
                    </Typography>
                    <div>
                      {leftList.map((item) => (
                        <div
                          id="availableEventMainBtn"
                          key={item}
                          button
                          onClick={() => moveItemToRight(item)}
                        >
                          <span id="leftText">{item}</span>
                          <span id="rightIcon">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                        </div>
                      ))}
                    </div>
                  </Box>

                  {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button id="arrowButtonForwebhook" variant="outlined" color="primary" onClick={() => moveItemToRight(leftList[0])}>
                  <ArrowForward />
                </Button>
                <Divider sx={{ my: 2 }} />
                <Button id="arrowButtonForwebhook" variant="outlined" color="primary" onClick={() => moveItemToLeft(rightList[0])}>
                  <ArrowBack />
                </Button>
              </Box> */}

                  <Box
                    id="subscribedEventBox"
                    sx={{
                      width: "50%",
                      minHeight: "100px",
                      border: "2px solid #EEE",
                      borderRadius: "5px",
                      boxShadow:"#DADADA 3px 3px 6px"
                    }}
                  >
                    <Typography
                      style={{
                        background: "#EEE",
                        borderTopLeftRadius: "1px",
                        borderTopRightRadius: "1px",
                        Padding: "20px",
                      }}
                      variant="h6"
                    >
                      {t("lbl_subscribed_event")}
                    </Typography>
                    <div>
                      {rightList.map((item) => (
                        <div
                          id="subscribedEventMainBtn"
                          key={item}
                          button
                          onClick={() => moveItemToLeft(item)}
                        >
                          <span id="leftIcon">
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                          </span>
                          <span id="rightText">{item}</span>
                        </div>
                      ))}
                    </div>
                  </Box>
                </Box>
                <div style={{marginTop:"20px"}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}></GridItem>
                  <GridItem xs={12} sm={12} md={3}></GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                      <FormControl sx={{ width: "100%" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            color: "#FFFFFF",
                            width: "100%",
                            height: "55px",
                          }}
                          className={classes.button}
                          onClick={() => {
                            clearInterval();
                            setShowCreateBlock(false);
                          }}
                        >
                          {t("lbl_cancel")}
                        </Button>
                      </FormControl>
                    </GridItem>
                    
                    <GridItem xs={12} sm={12} md={3}>
                      <FormControl sx={{ width: "100%" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          id="btnGreenAdd"
                          style={{
                            color: "#FFFFFF",
                            width: "100%",
                            height: "55px",
                          }}
                          className={classes.button}
                          onClick={saveWebhook}
                        >
                          {t("lbl_save")}
                        </Button>
                      </FormControl>
                    </GridItem>

                  </GridContainer>
                  </div>
              </>
            ) : (
              ""
            )}
            {!showCreateBlock ? (
              <div id="deviceListtable" class="deviceListtable">
                <MUIDataTable
                  id="merchantListTable"
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </div>
            ) : (
              ""
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
