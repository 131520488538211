/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@mui/icons-material/Dashboard";
import Person from "@mui/icons-material/Person";
import NotificationImportant from "@mui/icons-material/NotificationImportant";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import LogDetail from "views/LogDetail/LogDetail.js";
import DeviceManager from "views/DeviceManager/DeviceManager.js";
import Notification from "views/Notification/Notification.js";
import IncorrectBillingAmountKushki from "views/IncorrectBillingAmountKushki/IncorrectBillingAmountKushki.js";
import BatchMatch from "views/BatchMatch/BatchMatch.js";
import OpenBatch from "views/OpenBatch/OpenBatch.js";
import DevTools from "views/DevTools/DevTools.js";
import TransactionDetail from "views/TransactionDetail/TransactionDetail.js";
import AdminEmployee from "views/AdminEmployee/AdminEmployee.js";
import EditMerchant from "views/EditMerchant/EditMerchant";
import ResetPassword from "views/ResetPassword/ResetPassword.js";
import Logout from "views/Logout/Logout.js";
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import BallotIcon from '@mui/icons-material/Ballot';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ConstructionIcon from '@mui/icons-material/Construction';
import TestingPage from "views/Testing/Testing.js";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import SecurityKey from "views/SecurityKey/SecurityKey.js";
import Webhook from "views/Webhook/Webhook.js";
import KeyIcon from '@mui/icons-material/Key';
import TranslateIcon from '@mui/icons-material/Translate';
import WebhookIcon from '@mui/icons-material/Webhook';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: " ",
    icon: Dashboard,
    component: <DashboardPage />,
    layout: "/admin",
  },
  {
    path: "/edit_merchant",
    name: "EditMerchant",
    rtlName: " ",
    icon: Person,
    component: <EditMerchant />,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "Add Merchant",
    rtlName: "  ",
    icon: Person,
    component: <UserProfile />,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "Merchant List",
    rtlName: " ",
    icon: ContentPasteIcon,
    component: <TableList />,
    layout: "/admin",
  },
  {
    path: "/create_key",
    name: "API Security Key",
    rtlName: " ",
    icon: KeyIcon,
    component: <SecurityKey />,
    layout: "/admin",
  },
  {
    path: "/webhooks",
    name: "Webhooks",
    rtlName: " ",
    icon: WebhookIcon,
    component: <Webhook />,
    layout: "/admin",
  },
  {
    path: "/device_manager",
    name: "Device Manager",
    rtlName: "",
    icon: PhoneAndroidIcon,
    component: <DeviceManager />,
    layout: "/admin",
  },
  {
    path: "/log",
    name: "Log Detail",
    rtlName: "",
    icon: DeveloperModeIcon,
    component: <LogDetail />,
    layout: "/admin",
  },
  {
    path: "/incorrect_billing_amount_Kushkis",
    name: "Reports",
    rtlName: "  ",
    icon: ContentPasteIcon,
    component: <IncorrectBillingAmountKushki />,
    layout: "/admin",
    subMenuFlag: "1",
    subMenu: [
      {
        path: "/incorrect_billing_amount_Kushki",
        name: "Incorrect Billing Amount (Kushki)",
        rtlName: " ",
        icon: ContentPasteIcon,
        component: <IncorrectBillingAmountKushki />,
        layout: "/admin",
        subKeyVal: 12
      },
    ]
  }, 
  {
    path: "/notification",
    name: "Notification",
    rtlName: "",
    icon: NotificationImportant,
    component: <Notification />,
    layout: "/admin",
  },
  {
    path: "/batchmatch",
    name: "BatchMatch",
    rtlName: "",
    icon: ContentPasteIcon,
    component: <BatchMatch />,
    layout: "/admin",
  },
  {
    path: "/transaction_detail",
    name: "TransDetail",
    rtlName: "",
    icon: ContentPasteIcon,
    component: <TransactionDetail />,
    layout: "/admin",
  },
  {
    path: "/open_batch",
    name: "OpenBatch",
    rtlName: "",
    icon: BallotIcon,
    component: <OpenBatch />,
    layout: "/admin",
  },
  {
    path: "/dev_tools",
    name: "devTools",
    rtlName: "",
    icon: ConstructionIcon,
    component: <DevTools />,
    layout: "/admin",
  },
  {
    path: "/admin_employees",
    name: "Users",
    rtlName: "",
    icon: Person,
    component: <AdminEmployee />,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    rtlName: " ",
    icon: LogoutIcon,
    component: <Logout />,
    layout: "/user",
  },
  {
    path: "/changeLanguage",
    name: "English | Español",
    rtlName: " ",
    icon: TranslateIcon,
    component: "",
    layout: "/admin",
  },
];

export default dashboardRoutes;
