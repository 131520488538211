/*
  @page Item listing
*/

//Import require libraries
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Helper from "./../../util/Helper";
import SelectMui from '@mui/material/Select';
import MenuItemMui from '@mui/material/MenuItem';
import FormControlMui from '@mui/material/FormControl';
import InputLabelMui from '@mui/material/InputLabel';
import OutlinedInputMui from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';       
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CSVLink, CSVDownload } from 'react-csv';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

/*
  @const Page styles
*/


function CustomToolbar() {
  return (
    <GridToolbarContainer>      
       <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }}  />  */}
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarFilterButton /> */}

    </GridToolbarContainer>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};
function getStyles(category, categoryID, theme) {
  return {
    fontWeight:
      categoryID.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const useStyles = makeStyles(styles);

/*
  @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

/*
  @function Item listing page logic
*/
var seletedCheckBox = {};
export default function TableList() {
   const {t} = useTranslation();
  const [tableData, setTableData] = useState(false);
  const [originalTableData, setOrignalTableData] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [itemId, setItemId] = useState(0);
  const [itemDeleteDailog, setItemDeleteDailog] = useState(false);
  const [itemExportDailog, setItemExportDailog] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [multiCatShow, setMultiCatShow] = useState(false);
  const [formValidationError, setFormValidationError] = useState(true);
  const [openAddItem, setOpenAddItem] = React.useState(false);
  const [openEditItem, setOpenEditItem] = React.useState(false);
  const [itemName, setItemName] = React.useState("");
  const [itemColor, setColor] = React.useState("");
  const [pricingType, setPricingType] = React.useState("Fixed Price");
  const [unit, setUnit] = React.useState("");
  const [showInPOS, setShowInPOS] = React.useState(true);
  const [category, setcategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [multiSelectedCategory, setMultiSelectedCategory] = React.useState([]);
  const [modifire, setModifire] = React.useState([]);
  const [selectedModifire, setSelectedModifire] = React.useState([]);
  const [tax, setTaxName] = React.useState([]);
  const [selectedTaxes, setSelectedTaxes] = React.useState([]);
  const [sellPrice, setSellPrice] = React.useState("");
  const [costPrice, setCostPrice] = React.useState("");
  const [sku, setSKU] = React.useState("");
  const [posId, setPosId] = React.useState("");

  const [multiItemDeleteDailog, setMultiItemDeleteDailog] = useState(false);
  const [multiItemDuplicateDailog, setMultiItemDuplicateDailog] = useState(false);
  const [bulkActionDropdownValueZero, setBulkActionValueZero] = useState("");
  const [accessPage, setAccessPage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  const localeText = {
    toolbarColumns: t('lbl_Columns'), 
    toolbarDensity: t('lbl_Density'),     
  };
  /*
    @function For get categories,taxes and modifiers
  */
  const getCategoryList = () => {
    const data = {};
    const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('merchantToken') }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_add_form_detail', data, {
      headers: headers
    }).then(response => {
      const catName = new Object();
      const taxName = new Object();
      const modifireName = new Object();
      response.data.catData.forEach((eachCategory) => { //Fetch all category
        catName[eachCategory.id] = eachCategory.name;
      });

      response.data.taxesData.forEach((eachTax) => { //Fetch all taxes
        taxName[eachTax.id] = eachTax.name;
      });

      response.data.modifireData.forEach((eachModifire) => { //Fetch all modifiers
        modifireName[eachModifire.id] = eachModifire.name;
      });
      setcategory(catName);
      setTaxName(taxName);
      setModifire(modifireName);
    }).catch(error => {
      return error;
    });
  }

  /*
    @function For set all values in edit dialog
  */
  const setEditVal = (editVal) => {
    setItemId(editVal.id);
    setItemName(editVal.name);
    setColor(editVal.color);
    setShowInPOS((editVal.show_in_pos == 1) ? true : false);
    setSelectedCategory(editVal.category_id.split(","));
    if (editVal.modifire_id !== '' && editVal.modifire_id !== null) {
      setSelectedModifire(editVal.modifire_id.split(","));
    }
    var sellprice = Helper.viewCurrencyFormat(parseFloat(editVal.sell_price));
    setSellPrice(sellprice);
    var costprice = Helper.viewCurrencyFormat(parseFloat(editVal.cost_price));
    setCostPrice(costprice);
    setSKU(editVal.sku);
    if (editVal.taxes) {
      setSelectedTaxes(editVal.taxes.split(","));
    }
    setPosId(editVal.pos_id);
    setPricingType(editVal.price_type);
    setUnit(editVal.unit_name);
  }

  /*
    @function For clear all variables
  */
  const clearVal = () => {
    setItemId("");
    setItemName("");
    setColor("");
    setShowInPOS(true);
    setSelectedCategory([]);
    setSelectedModifire([]);
    setSellPrice("");
    setCostPrice("");
    setSKU("");
    setSelectedTaxes([]);
    setPosId("");
    setUnit("");
    setPricingType("Fixed Price");
    setSearchQuery("");
  }

  /*
    @function For validation
  */
  const validateVal = () => {
    var errorMessageString = '';
    if (itemName === '' || itemName === null) //Check item name was null or blank
      errorMessageString += " "+t('msg_please_enter_item_name');

    if (selectedCategory.toString() === '' || selectedCategory.toString() === null) //Check selected category was null or blank
      errorMessageString += '\n '+t('msg_please_select_category');
    if (pricingType != 'Variable') { //Check pricing type was not variable
      if (sellPrice === '' || sellPrice === null) //Check sell price was null or blank
        errorMessageString += '\n '+t('msg_please_enter_sell_price');
    }
    setErrorMsg(errorMessageString);
    if (errorMessageString === '') {
      setError(false);
      return true;
    }
    else {
      setError(true);
      return false;
    }

  }

  /*
    @function For get item list data
  */
  const getItemList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_item_list', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      const exportArr = [];
      exportArr.push(["ID", "Color", "Name", "Show In POS ", "Amount", "Modifier Groups", "Categories", "POS Id",]);
      const modArr = response.data.modifierData;
      const catArr = response.data.catData;

      response.data.itemData.forEach((eachItem) => {
        var catIds = [];
        if (eachItem.category_id !== '' && eachItem.category_id !== null) { //Check category id was blank or null
          catIds = eachItem.category_id.split(",");
        } else {
          catIds[0] = "";
        }

        var catNameString = "";
        if (catIds[0] !== '') { //Check category id was not blank
          catIds.forEach((eachcatId) => {
            catNameString = catNameString + catArr[eachcatId]["name"] + ",";
          });

        }

        if (catNameString !== "") { //Check category name string was not blank
          catNameString = catNameString.substring(0, catNameString.length - 1);
        }

        var modIds = [];
        if (eachItem.modifire_id !== '' && eachItem.modifire_id !== null) { //Check modifier id was not blank or null
          modIds = eachItem.modifire_id.split(",");
        } else {
          modIds[0] = "";
        }

        var modNameString = "";
        if (modIds[0] !== '') { //Check modifier id was not blank
          modIds.forEach((eachmodId) => {

            if (typeof modArr[eachmodId] !== 'undefined') {
              modNameString = modNameString + modArr[eachmodId]["name"] + ",";
            }

          });
        }

        if (modNameString !== "") { //Check modifier name string was not blank
          modNameString = modNameString.substring(0, modNameString.length - 1);
        }
        var sellprice = "";
        sellprice = Helper.viewCurrencyFormat(parseFloat(eachItem.sell_price));
        let item = {}
        item["id"] = eachItem.id;
        item["color"] = <div id="itemColorDiv" style={{marginTop:"15px"}}><div style={{ borderRadius: "2px", width: "20px", height: "20px", background: eachItem.color }}></div></div>;
        item["name"] = eachItem.name;
        item["show_in_POS"] = (eachItem.show_in_pos == 1) ? <div style={{marginTop:"6px"}}><CheckIcon /></div> : <div><CloseIcon /></div>;
        item["amount"] = sellprice;
        item["mdifier_groups"] = modNameString;
        item["categories"] = catNameString;
        item["POS_ID"] = eachItem.pos_id;
        item["edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none',marginTop:"15px" }} onClick={(e) => { setEditVal(eachItem); setOpenEditItem(true); }}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        item["delete"] = <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none',marginTop:"15px" }} onClick={(e) => { setItemId(eachItem.id); setItemDeleteDailog(true); }} ><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([<input type="checkbox" id={"row_"+eachItem.id} onChange={(e) => {(e.target.checked)?seletedCheckBox[eachItem.id] = eachItem.id:delete seletedCheckBox[eachItem.id];}} />, <div style={{borderRadius:"2px", width:"20px",height:"20px",background:eachItem.color}}></div>,eachItem.name,  (eachItem.show_in_pos == 1) ? <CheckIcon /> : <CloseIcon />, sellprice, modNameString, catNameString, eachItem.pos_id, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={(e) => { setEditVal(eachItem); setOpenEditItem(true); }}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={(e) => { setItemId(eachItem.id); setItemDeleteDailog(true); }} ><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(item);
        exportArr.push([eachItem.id, eachItem.color, eachItem.name, (eachItem.show_in_pos == 1) ? "Yes" : "No", sellprice, modNameString, catNameString, eachItem.pos_id])
      });

      setTableData(userArr);
      setOrignalTableData(userArr);
      setCsvData(exportArr);


    }).catch(error => {
      return error;
    });
  }


  const columns = [
    {
      field: 'id', 
      headerName: '',  
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'color',
      headerName: t('Color'),
      flex: 1,
      renderCell: (params) => params.value,
      sortable: false

    },
    {
      field: 'name',
      headerName: t('lbl_name'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'show_in_POS',
      headerName: t('lbl_show_in_pos'),
      flex: 1,
      renderCell: (params) => params.value,
      sortable: false
    },
    {
      field: 'amount',
      headerName: t('lbl_amount'),
      flex: 1,
      renderCell: (params) => params.value
    }
    ,
    {
      field: 'mdifier_groups',
      headerName: t('lbl_modifier_groups'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'categories',
      headerName: t('lbl_categories'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'POS_ID',
      headerName: t('lbl_pos_id'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'edit',
      headerName: t('lbl_edit'),
      flex: 1,
      disableExport: true,
      renderCell: (params) => params.value,
      sortable: false
    },
    {
      field: 'delete',
      headerName: t('lbl_delete'),
      flex: 1,
      disableExport: true,
      renderCell: (params) => params.value,
      sortable: false
    }
  ];



  // handleItemNameChange
  const handleItemNameChange = (event) => {
    const result = event.target.value.replace(/[^a-z1-9 ]/gi, '');
    setItemName(result)
  }

  // const handleSetSku = (event) => {
  //   const result = event.target.value.replace(/[^a-z1-9 ]/gi, '');
  //   setSKU(event.target.value.replace(/[^a-z1-9 ]/gi, ''))
  // }

  /*
    @function For edit item
  */
  const editItem = () => {
    const data = {
      id: itemId,
      name: itemName,
      color: itemColor,
      show_in_pos: (showInPOS == true) ? 1 : 0,
      category_id: selectedCategory.toString(),
      modifire_id: selectedModifire.toString(),
      sell_price: sellPrice == '' ? 0 : sellPrice,
      cost_price: costPrice == '' ? 0 : costPrice,
      sku: sku,
      taxes: selectedTaxes.toString(),
      pos_id: posId,
      price_type: pricingType,
      unit_name: unit
    };
    setOpenEditItem(false);
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'update_item', data, {
      headers: headers
    }).then(response => {
      clearVal();
      getItemList();
      setSuccess(true);
      setSuccessMsg(t('msg_item_updated_successfully'));

    }).catch(error => {
      clearVal();
      getItemList();
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });
  }

  /*
    @function For delete item
  */
  const deleteItem = (itemID) => {
    setTableData(false);
    setItemDeleteDailog(false);
    const data = { id: itemID };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_item', data, {
      headers: headers
    }).then(response => {
      getItemList();
      setItemId(0);
      if (response.data == 1) { //Check item was deleted or not
        setSuccess(true);
        setSuccessMsg(t('msg_item_deleted_successfully'));
      } else {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
      }


    }).catch(error => {
      getItemList();
      setItemId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }


  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setItemDeleteDailog(false);
    setError(false);
    setSuccess(false);
  };


  /*
    @function For handle multiselected item actions like delete or duplicate 
  */
  const multiselect_action = (action_name) => {
    if (action_name === "category" && multiSelectedCategory.length === 0){
      setError(true);
      setErrorMsg(t('msg_please_select_category'));
      return;
    }
    const data = { idArr: seletedCheckBox, operaton: action_name, category: multiSelectedCategory.toString() };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'multiple_item_action', data, {
      headers: headers
    }).then(response => {
      getItemList();
      if (response.data == 1) {
        setSuccess(true);
        if (action_name === "delete") //Check item was deleted or not
        {
          setSuccessMsg(t('msg_selected_items_deleted_successfully'));
        } else if (action_name === "duplicate") //Check item was duplicated or not
        {
          setSuccessMsg(t('msg_selected_items_duplicated_successfully'));
        } else if (action_name === "category") //Check categories was updated on selected item or not
        {
          setSuccessMsg(t('msg_categories_updated_successfully_on_selected_items'));
        }

      } else {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
      }
    }).catch(error => {
      getItemList();
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });
  }

  const csvLink = useRef();
  const handleExport = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_item_list', data, {
      headers: headers
    }).then(response => {
      const exportArr = [];
      exportArr.push([t('lbl_id'), t('lbl_color'), t('lbl_name'), t('lbl_show_in_pos'), t('lbl_amount'), t('Modifier_Groups'), t('lbl_categories'), t('lbl_pos_id'),]);
      const modArr = response.data.modifierData;
      const catArr = response.data.catData;

      response.data.itemData.forEach((eachItem) => {
        var catIds = [];
        if (eachItem.category_id !== '' && eachItem.category_id !== null) { //Check category id was blank or null
          catIds = eachItem.category_id.split(",");
        } else {
          catIds[0] = "";
        }

        var catNameString = "";
        if (catIds[0] !== '') { //Check category id was not blank
          catIds.forEach((eachcatId) => {
            catNameString = catNameString + catArr[eachcatId]["name"] + ",";
          });

        }

        if (catNameString !== "") { //Check category name string was not blank
          catNameString = catNameString.substring(0, catNameString.length - 1);
        }

        var modIds = [];
        if (eachItem.modifire_id !== '' && eachItem.modifire_id !== null) { //Check modifier id was not blank or null
          modIds = eachItem.modifire_id.split(",");
        } else {
          modIds[0] = "";
        }

        var modNameString = "";
        if (modIds[0] !== '') { //Check modifier id was not blank
          modIds.forEach((eachmodId) => {

            if (typeof modArr[eachmodId] !== 'undefined') {
              modNameString = modNameString + modArr[eachmodId]["name"] + ",";
            }

          });
        }

        if (modNameString !== "") { //Check modifier name string was not blank
          modNameString = modNameString.substring(0, modNameString.length - 1);
        }
        var sellprice = "";
        sellprice = Helper.viewCurrencyFormat(parseFloat(eachItem.sell_price));
        exportArr.push([eachItem.id, eachItem.color, eachItem.name, (eachItem.show_in_pos == 1) ? "Yes" : "No", sellprice, modNameString, catNameString, eachItem.pos_id])
      });

      setCsvData(exportArr);
      csvLink.current.link.click();

    }).catch(error => {
      return error;
    });
   
  };

  const showExportDailog = () => {
    setItemExportDailog(true);
  }
  /*
    @function For add new item
  */
  const saveItem = () => {
    if (validateVal() === true) { //Check if all value is proper or not
      const data = {
        name: itemName,
        color: itemColor,
        show_in_pos: (showInPOS == true) ? 1 : 0,
        category_id: selectedCategory.toString(),
        modifire_id: selectedModifire.toString(),
        sell_price: sellPrice == '' ? 0 : sellPrice,
        cost_price: costPrice == '' ? 0 : costPrice,
        sku: sku,
        taxes: selectedTaxes.toString(),
        pos_id: posId,
        price_type: pricingType,
        unit_name: unit
      };
      setOpenAddItem(false);
      setTableData(false);
      const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
      }
      data.parent_id = localStorage.getItem('parent_id');
      data.location_id = localStorage.getItem('location_id');
      axios.post(window.apiUrl + 'add_item', data, {
        headers: headers
      }).then(response => {
        clearVal();
        getItemList();
        setSuccess(true);
        setSuccessMsg(t('msg_item_added_successfully'));

      }).catch(error => {
        getItemList();
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
        return error;
      });
    }

  }

  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissonData) {
      if (permissonData.access_item_catalog === 0 || permissonData.access_to_online_dashboard === 0) {
        setAccessPage(0);
        return;
      }
    }


    getCategoryList();
    getItemList();

  }, []);

  const handleSearch = (e) =>
  {  
    setSearchQuery(e.target.value);  
    const filteredData = originalTableData.filter((row) =>
      Object.values(row).some(
        (value) =>
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      )
    );
    setTableData(filteredData);
  }
  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_item_list')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if data was not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_item_list')}</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t('lbl_loading_item')}</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
      
      {
        (csvData) ? <CSVLink data={csvData} ref={csvLink} target='_blank' filename={"export-items.csv"}></CSVLink> : ""
      }
      <GridItem xs={12} sm={12} md={12}>

        {/* 
          Display error message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
          Display success message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_item_list')}</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                style={{ width: '100%' }}
                label={t('lbl_search')}
                variant="outlined"
                value={searchQuery}
                onChange={(e) => {handleSearch(e) }}
              />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControlMui className={classes.margintopid} id="aaaaaa" sx={{ width: "100%" }} style={{ width: "100%", float: "right", marginBottom: "10px" }}>
                  <InputLabelMui id="demo-simple-select-helper-label">{t('lbl_bulk_action')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    input={<OutlinedInputMui label="Bulk Action" />}
                    value={bulkActionDropdownValueZero}
                    onChange={(e) => {
                      if (e.target.value === "delete") {
                        setMultiItemDeleteDailog(true)
                        // multiselect_action("delete");
                      } else if (e.target.value === "duplicate") {
                        // multiselect_action("duplicate");
                        setMultiItemDuplicateDailog(true)
                      }
                      else if (e.target.value === "category") {
                        setMultiCatShow(true);
                      }
                    }}
                  >
                    <MenuItemMui value={"delete"}>{t('lbl_delete')}</MenuItemMui>
                    <MenuItemMui value={"duplicate"}>{t('lbl_duplicate')}</MenuItemMui>
                    <MenuItemMui value={"category"}>{t('lbl_categoty')}</MenuItemMui>
                  </SelectMui>

                </FormControlMui>

                {/* <FormControl style={{width:"200px",float:"right"}}>
            <InputLabel id="demo-simple-select-label">Bulk Action</InputLabel>
            <Select
              style={{"float":"right"}}
              label="Bulk Action"
              value={bulkActionDropdownValueZero}
              onChange={(e) => { 
               if(e.target.value === "delete"){
                setMultiItemDeleteDailog(true)
                // multiselect_action("delete");
                }else if(e.target.value === "duplicate")
                {
                  // multiselect_action("duplicate");
                  setMultiItemDuplicateDailog(true)
                }
                else if(e.target.value === "category")
                {
                  setMultiCatShow(true);
                }
               }}
            >
              <option disabled selected value="0">Bulk Action</option>
              <option style={{marginTop:"5px"}} id="dpbulkactions" value="delete">Delete</option>
              <option style={{marginTop:"5px"}} id="dpbulkactions" value="duplicate">Duplicate</option>              
              <option style={{marginTop:"5px"}} id="dpbulkactions" value="category">Category</option>              
            </Select>
            </FormControl> */}
              </GridItem>
              <GridItem xs={12} sm={12} md={3} >

                <GridContainer style={{width:"100%"}}>
                    <GridItem xs={12} sm={12} md={4}>
                    <center>
                      <FormControl sx={{ width: "100%",alignItems:"center" }}>
                      <Button
                      id="btnGreenAdd"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      style={{ backgroundColor: '#ffa726', color: '#FFFFFF',height:'55px',width: "50px" }}
                      onClick={() => { setOpenAddItem(true) }}
                    >+</Button>
                      </FormControl>
                    </center>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                    <center>
                      <FormControl sx={{ width: "100%" }}>
                      <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      style={{height:'55px' }}
                      startIcon={<FileDownloadIcon />}
                      onClick={showExportDailog}
                    >
                      {t('lbl_export')}

                    </Button>
                      </FormControl>
                    </center>
                      </GridItem>

                  </GridContainer>

                {/* <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<FileDownloadIcon />}
                  >
                    Import
                    <VisuallyHiddenInput type="file" />
                  </Button> */}
              </GridItem>

              


            </GridContainer>
            {/* 
              *Display item listing
            */}
           <div style={{marginTop:"20px"}}>
            <DataGrid

              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              onRowSelectionModelChange={ids => { seletedCheckBox = ids }}
              slots={{ toolbar: CustomToolbar }}
              rows={tableData}
              columns={columns}
              localeText={localeText}
              checkboxSelection
              disableRowSelectionOnClick

            />
               </div>
            {/* <Table
              tableHeaderColor="primary"
              tableHead={["","Color","Name",  "Show In POS", "Amount", "Modifier Groups", "Categories", "POS ID", "Edit", "Delete"]}
              tableData={tableData}
            /> */}
          </CardBody>
        </Card>

        {/* 
              item export confirm dailog
            */}
        <Dialog
          open={itemExportDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_export_items')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setItemExportDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { setItemExportDailog(false); handleExport(); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
           * Delete Item  popup start
        */}
        <Dialog
          open={itemDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_item')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setItemId(0); setItemDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { deleteItem(itemId) }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={multiItemDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_selected_items')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setMultiItemDeleteDailog(false); setBulkActionValueZero(""); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { multiselect_action("delete"); setMultiItemDeleteDailog(false); setBulkActionValueZero(""); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={multiItemDuplicateDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_duplicate_selected_items')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setMultiItemDuplicateDailog(false); setBulkActionValueZero(""); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={(e) => { multiselect_action("duplicate"); setMultiItemDuplicateDailog(false); setBulkActionValueZero(""); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          *Multiple Item category change  popup start
        */}
        <Dialog
          open={multiCatShow}
          fullWidth={true}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('lbl_select_category')}</DialogTitle>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={multiSelectedCategory}
            onChange={(e) => { setMultiSelectedCategory(e.target.value) }}
            input={<Input id="select-multiple-chip" />}
            renderValue={(multiSelectedCategory) => (
              <div className={classes.chips}>
                {
                  multiSelectedCategory.map((value) => (
                    <Chip key={value} label={category[value]} className={classes.chip} />
                  ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {
              Object.entries(category).map(([key, value]) => (
                <MenuItem key={key} value={key} >
                  {value}
                </MenuItem>

              ))
            }
          </Select>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setMultiCatShow(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_cancel')}
            </Button>
            <Button id="btnGreenAdd" onClick={(e) => { multiselect_action("category"); setMultiCatShow(false); }} color="default" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_done')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          *Add Item form start 
        */}
        <Dialog open={openAddItem} 
        fullWidth
        maxWidth={"md"}
        onClose={() => { setOpenAddItem(false); }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('lbl_add_new_item')}</DialogTitle>
          <DialogContent>

            <TextField
              margin="full"
              variant="outlined"
              id="name"
              style={{marginTop:"8px"}}
              label={t('lbl_item_name')}
              onChange={handleItemNameChange}
              value={itemName}
              fullWidth
            // (e) => setItemName(e.target.value)
            />
            <FormControlLabel xs={6} sm={6} md={6}
              control={
                <Checkbox
                  checked={showInPOS}
                  onChange={(e) => setShowInPOS(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t('lbl_show_in_pos')}
            />
            <div style={{ clear: 'both' }}></div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} style={{ width: "100%", marginTop: "15px" }}>
                  <InputLabelMui id="demo-simple-select-label">{t('lbl_color')}</InputLabelMui>
                  <SelectMui
                    className={classes.setFieldHeight}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('lbl_color')}
                    value={itemColor}
                    onChange={(e) => setColor(e.target.value)}
                  >
                    <MenuItem value={"Black"}>Black</MenuItem>
                    <MenuItem value={"Blue"}>Blue</MenuItem>
                    <MenuItem value={"Gray"}>Gray</MenuItem>
                    <MenuItem value={"Green"}>Green</MenuItem>
                    <MenuItem value={"Orange"}>Orange</MenuItem>
                    <MenuItem value={"Red"}>Red</MenuItem>
                  </SelectMui>
                </FormControlMui>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-mutiple-chip-label">{t('lbl_categoty')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-mutiple-chip-label"
                    className={classes.setFieldHeight}
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedCategory}
                    onChange={(e) => { setSelectedCategory(e.target.value) }}
                    input={<OutlinedInputMui label={t('lbl_categoty')} id="select-multiple-chip" />}
                    renderValue={(selectedCategory) => (
                      <div className={classes.chips}>
                        {
                          selectedCategory.map((value) => (
                            <Chip key={value} label={category[value]} className={classes.chip} />
                          ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      Object.entries(category).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>

                      ))
                    }
                  </SelectMui>
                </FormControlMui>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} style={{marginTop:"15px"}} >
                  <InputLabelMui id="demo-mutiple-chip-label">{t('lbl_modifier_groups')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-mutiple-chip-label"
                    className={classes.setFieldHeight}
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedModifire}
                    onChange={(e) => { setSelectedModifire(e.target.value) }}
                    input={<OutlinedInputMui label={t('lbl_modifier_groups')} id="select-multiple-chip" />}
                    renderValue={(selectedModifire) => (
                      <div className={classes.chips}>
                        {
                          selectedModifire.map((value) => (
                            <Chip key={value} label={modifire[value]} className={classes.chip} />
                          ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      Object.entries(modifire).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>

                      ))
                    }
                  </SelectMui>
                </FormControlMui>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth}  style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-mutiple-chip-label">{t('lbl_taxes')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-mutiple-chip-label"
                    className={classes.setFieldHeight}
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedTaxes}
                    onChange={(e) => { setSelectedTaxes(e.target.value) }}
                    input={<OutlinedInputMui label={t('lbl_taxes')} id="select-multiple-chip" />}
                    renderValue={(selectedTaxes) => (
                      <div className={classes.chips}>
                        {selectedTaxes.map((value) => (
                          <Chip key={value} label={tax[value]} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      Object.entries(tax).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>
                      ))
                    }
                  </SelectMui>
                </FormControlMui>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} >
                <FormControlMui className={classes.formControl} style={{ marginTop: "15px" }}>
                  <InputLabelMui id="demo-simple-select-label">{t('lbl_pricing_type')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('lbl_pricing_type')}
                    value={pricingType}
                    onChange={(e) => { setPricingType(e.target.value); setSellPrice(""); setCostPrice("") }}
                  >
                    <MenuItem value={"Fixed Price"}>{t('lbl_fixed_price')}</MenuItem>
                    <MenuItem value={"Variable"}>{t('lbl_variable')}</MenuItem>
                    <MenuItem value={"Per Unit"}>{t('lbl_per_unit')}</MenuItem>

                  </SelectMui>
                </FormControlMui>
              </GridItem>
            </GridContainer>


            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="sellPrice"
                  label={t('lbl_sell_price')}
                  dir="rtl"
                  onChange={(e) => { setSellPrice(Helper.currencyFormatWithDot(e, 'sellPrice')) }}
                  value={sellPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '',marginTop:"15px" }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="costPrice"
                  label={t('lbl_cost_price')}
                  dir="rtl"
                  onChange={(e) => setCostPrice(Helper.currencyFormatWithDot(e, 'costPrice'))}
                  value={costPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '',marginTop:"15px" }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
            </GridContainer>


            <TextField
              margin="full"
              variant="outlined"
              id="unit"
              label={t('lbl_enter_unit')}
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              fullWidth
              style={{ display: (pricingType == 'Per Unit') ? "" : "none",marginTop:"15px" }}
            />

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="sku"
                  label={t('lbl_sku')}
                  style={{marginTop:"15px"}}
                  onChange={(e) => setSKU(e.target.value.replace(/[^a-z1-9 ]/gi, ''))}
                  value={sku}
                  fullWidth
                // (e) => setSKU(e.target.value)
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="posId"
                  type="number"
                  label={t('lbl_pos_id')}
                  style={{marginTop:"15px"}}
                  onChange={(e) => setPosId(e.target.value)}
                  value={posId}
                  fullWidth
                />
              </GridItem>
            </GridContainer>


          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOpenAddItem(false); clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveItem() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_save_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>

        {/*
          *Edit Item form start 
         */}
        <Dialog open={openEditItem} 
        fullWidth
        maxWidth={"md"}
        onClose={() => { setOpenEditItem(false) }} 
        aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('lbl_edit_item')}</DialogTitle>
          <DialogContent>


            <TextField
              margin="full"
              variant="outlined"
              id="name"
              label={t('lbl_item_name')}
              style={{marginTop:"15px"}}
              onChange={(e) => setItemName(e.target.value.replace(/[^a-z1-9 ]/gi, ''))}
              value={itemName}
              fullWidth
            />

            <FormControlLabel
              margin="dense"
              control={
                <Checkbox
                  checked={showInPOS}
                  onChange={(e) => setShowInPOS(e.target.checked)}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t('lbl_show_in_pos')}
            />
            <div style={{ clear: 'both' }}></div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} xs={12} sm={12} md={6} style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-simple-select-label">{t('lbl_color')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-simple-select-label"
                    className={classes.setFieldHeight}
                    label={t('lbl_color')}
                    id="demo-simple-select"
                    value={itemColor}
                    onChange={(e) => setColor(e.target.value)}
                  >
                    <MenuItem value={"Black"}>Black</MenuItem>
                    <MenuItem value={"Blue"}>Blue</MenuItem>
                    <MenuItem value={"Gray"}>Gray</MenuItem>
                    <MenuItem value={"Green"}>Green</MenuItem>
                    <MenuItem value={"Orange"}>Orange</MenuItem>
                    <MenuItem value={"Red"}>Red</MenuItem>

                  </SelectMui>
                </FormControlMui>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} xs={12} sm={12} md={6} style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-mutiple-chip-label">{t('lbl_categoty')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-mutiple-chip-label"
                    className={classes.setFieldHeight}
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedCategory}
                    onChange={(e) => { setSelectedCategory(e.target.value) }}
                    input={<OutlinedInputMui label={t('lbl_categoty')} id="select-multiple-chip" />}
                    renderValue={(selectedCategory) => (
                      <div className={classes.chips}>
                        {
                          selectedCategory.map((value) => (
                            <Chip key={value} label={category[value]} className={classes.chip} />
                          ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      Object.entries(category).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>

                      ))
                    }
                  </SelectMui>
                </FormControlMui>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} xs={6} sm={6} md={6} style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-mutiple-chip-label">{t('lbl_modifier_groups')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-mutiple-chip-label"
                    className={classes.setFieldHeight}
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedModifire}
                    onChange={(e) => { setSelectedModifire(e.target.value) }}
                    input={<OutlinedInputMui label={t('lbl_modifier_groups')} id="select-multiple-chip" />}
                    renderValue={(selectedModifire) => (
                      <div className={classes.chips}>
                        {
                          selectedModifire.map((value) => (
                            <Chip key={value} label={modifire[value]} className={classes.chip} />
                          ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      Object.entries(modifire).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>

                      ))
                    }
                  </SelectMui>
                </FormControlMui>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.popupHalfWidth} xs={6} sm={6} md={6} style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-mutiple-chip-label">{t('lbl_taxes')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-mutiple-chip-label"
                    className={classes.setFieldHeight}
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedTaxes}
                    onChange={(e) => { setSelectedTaxes(e.target.value) }}
                    input={<OutlinedInputMui label={t('lbl_taxes')}  id="select-multiple-chip" />}
                    renderValue={(selectedTaxes) => (
                      <div className={classes.chips}>
                        {selectedTaxes.map((value) => (
                          <Chip key={value} label={tax[value]} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {
                      Object.entries(tax).map(([key, value]) => (
                        <MenuItem key={key} value={key} >
                          {value}
                        </MenuItem>
                      ))
                    }

                  </SelectMui>
                </FormControlMui>
              </GridItem>

              <div style={{ clear: 'both' }}></div>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlMui className={classes.formControl} xs={6} sm={6} md={6} style={{marginTop:"15px"}}>
                  <InputLabelMui id="demo-simple-select-label">{t('lbl_pricing_type')}</InputLabelMui>
                  <SelectMui
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('lbl_pricing_type')}
                    value={pricingType}
                    onChange={(e) => { setPricingType(e.target.value); setSellPrice(""); setCostPrice("") }}
                  >
                    <MenuItem value={"Fixed Price"}>{t('lbl_fixed_price')}</MenuItem>
                    <MenuItem value={"Variable"}>{t('lbl_variable')}</MenuItem>
                    <MenuItem value={"Per Unit"}>{t('lbl_per_unit')}</MenuItem>

                  </SelectMui>
                </FormControlMui>
              </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="sellPrice"
                  label={t('lbl_sell_price')}
                  dir="rtl"
                  onChange={(e) => setSellPrice(Helper.currencyFormatWithDot(e, 'sellPrice'))}
                  value={sellPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '',marginTop:"15px" }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="costPrice"
                  label={t('lbl_cost_price')}
                  dir="rtl"
                  onChange={(e) => setCostPrice(Helper.currencyFormatWithDot(e, 'costPrice'))}
                  value={costPrice}
                  fullWidth
                  style={{ display: (pricingType == 'Variable') ? "none" : '',marginTop:"15px" }}
                  inputProps={{ min: 0, style: { textAlign: 'right' } }}
                />
              </GridItem>
            </GridContainer>

            <TextField
              margin="full"
              variant="outlined"
              id="unit"
              label={t('lbl_enter_unit')}
              onChange={(e) => setUnit(e.target.value)}
              value={unit}
              fullWidth
              style={{ display: (pricingType == 'Per Unit') ? "" : "none",marginTop:"15px" }}
            />

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  id="sku"
                  label={t('lbl_sku')}
                  style={{marginTop:"15px"}}
                  onChange={(e) => setSKU(e.target.value.replace(/[^a-z1-9 ]/gi, ''))}
                  value={sku}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TextField
              margin="full"
              variant="outlined"
                  type="number"
                  id="posId"
                  style={{marginTop:"15px"}}
                  label={t('lbl_pos_id')}
                  onChange={(e) => setPosId(e.target.value)}
                  value={posId}
                  fullWidth
                />
              </GridItem>
            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setOpenEditItem(false); clearVal(); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenAdd" onClick={() => { editItem() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_save_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}
