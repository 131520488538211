/*
   @page Dashboard
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { makeStyles } from "@mui/styles";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GroupIcon from '@mui/icons-material/Group';
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import AccessTime from "@mui/icons-material/AccessTime";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { bugs, website, server } from "variables/general.js";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {
  dailySalesChart,
  dailyTransChart,
} from "variables/charts.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Table from "components/Table/Table.js";

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { FlashOnRounded } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

/*
   @const Page styles
*/
const useStyles = makeStyles(styles);

/*
   @function Dashboard page logic
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Dashboard() {
  const {t} = useTranslation();
  const [totalTransaction, setTotalTransaction] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalCat, setTotalCat] = useState(0);
  const [totalTaxes, setTotalTaxes] = useState(0);
  const [totalItem, setTotalItem] = useState(false);
  const [totalMerchant, setTotalMerchant] = useState(0);
  const [weeklyRevenueData, setWeeklyRevenueData] = useState([]);
  const [weeklyTransData, setWeeklyTransData] = useState([]);


  /*
    @function Get dashboard data
  */
  const getDashboardDetail = () => {
    
    const data = {group_id:localStorage.getItem('group_id')};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_dashboard_detail', data, {
      headers: headers
    }).then(response => {

      if (response.data.totalAmount > 1000000) { //Check total amount is greater than 1000000 or not
        var x = response.data.totalAmount / 1000000;
        setTotalAmt((x.toFixed(2)) + " M");
      } else if (response.data.totalAmount > 1000) { //Check total amount is greater than 1000 or not
        var x = response.data.totalAmount / 1000;
        setTotalAmt((x.toFixed(2)) + " K");
      } else {
        setTotalAmt(response.data.totalAmount.toFixed(2));
      }

      setTotalTransaction(response.data.totalTransaction);
      setTotalCat(response.data.totalCategory);
      setTotalTaxes(response.data.totalTaxes);
      setTotalItem(response.data.totalItem);
      setTotalMerchant(response.data.totalMerchant);

      var weeklyRevenue = [];
        var weeklyTrans = [];
        response.data.weeklyChartData.forEach((obj, idx) => {
          weeklyRevenue.push({ day: obj.day, sales: obj.daily_total });
          weeklyTrans.push({ day: obj.day, sales: obj.total_rec });
        });
      
        setWeeklyRevenueData(weeklyRevenue);
        setWeeklyTransData(weeklyTrans);

    }).catch(error => { //set Error message
      
      return error;
    });
  }
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  
  const [filterMId, setFilterMId] = useState("");
  const [filterBusinessName, setFilterBusinessName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [batchTransDailog, setBatchTransDailog] = useState(false);
  const [transData, setTransData] = useState(false);
 

  const getBatchTrans = (e, batchId, serial_number) => {
    setBatchTransDailog(true);
    e.preventDefault();
    const data = { batch_id: batchId, serial_number: serial_number };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
   
    axios.post(window.apiUrl + 'get_open_batch_trans', data, {
      headers: headers
    }).then(response => {
      console.log(response);
      var data = [];
      response.data.transData.forEach((eachTrans) => {
          
        let transTime = (eachTrans.device_created_at) ? moment(new Date(eachTrans.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY HH:mm') : "";
        data.push([transTime, eachTrans.ecrref_num, <center>{(eachTrans.edc_type) ? eachTrans.edc_type : eachTrans.payment_method}</center>, <center>{eachTrans.auth_code}</center>, <div style={{ textAlign: 'center' }}>  {(eachTrans.transaction_type == "RETURN")?"-":""}{(eachTrans.ecrref_num) ? "$" : ""}  {(eachTrans.amount) ? eachTrans.amount: ""}</div>]);
        console.log("ddddddd---**");
      });
      setTransData(data);
      console.log(data);
    }).catch(error => {     
      return error;
    });
  }
  
 

  useEffect(() => {
    var permissionData = JSON.parse(localStorage.getItem("admin_permission"));    
    getDashboardDetail();    
  }, []);
  
  
  return (
    <div>

      {/* 
        @display total transaction, total revenue, total Categories, total Items
      */}
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssessmentIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_transactions')}</p>
              <h3 className={classes.cardTitle}>
                {(totalTransaction !== false) ? totalTransaction : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"} />}  </h3>

            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_revenue')}</p>
              <h3 className={classes.cardTitle}>{(totalAmt) ? "$" + totalAmt : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"} />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <GroupIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_merchants')}</p>
              <h3 className={classes.cardTitle}>{(totalMerchant) ? totalMerchant : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"} />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DynamicFeedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_items')}</p>
              <h3 className={classes.cardTitle}>{(totalItem !== false) ? totalItem : <CircularProgress className={"MuiCircularProgress-circleDisableShrink"}
              />}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      
      {/* 
        @display Last Week Revenue, Last Week Transactions graphs
      */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              {/* <ChartistGraph
                className="ct-chart"
                data={weeklyRevenueData}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              /> */}
              <LineChart
                height={400}
                xAxis={[{ scaleType: "band", data: weeklyRevenueData.map((d) => d.day) }]}
                series={[{ data: weeklyRevenueData.map((d) => d.sales), label: t('lbl_revenue'), color: "#333333" }]}
                grid={{ vertical: true, horizontal: true }}
                margin={{ left: 50, right: 40, top: 50, bottom: 50 }}
                sx={{
                  '& .MuiLineElement-root': {
                    strokeDasharray: '1 1',
                    strokeWidth: 2,
                  },
                  '& .MuiAreaElement-series-Germany': {
                    fill: "url('#myGradient')",
                  },
                }}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{t('lbl_last_week_all_merchant_revenue')}</h4>
             
            </CardBody>            
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="warning">
              {/* <ChartistGraph
                className="ct-chart"
                data={weeklyTransData}
                type="Line"
                options={dailyTransChart.options}
                listener={dailyTransChart.animation}
              /> */}
              <LineChart
                height={400}
                xAxis={[{ scaleType: "band", data: weeklyTransData.map((d) => d.day) }]}
                series={[{ data: weeklyTransData.map((d) => d.sales), label: t('lbl_transactions'), color: "#333333" }]}
                grid={{ vertical: true, horizontal: true }}
                margin={{ left: 50, right: 40, top: 50, bottom: 50 }}
                sx={{
                  '& .MuiLineElement-root': {
                    strokeDasharray: '1 1',
                    strokeWidth: 2,
                  },
                  '& .MuiAreaElement-series-Germany': {
                    fill: "url('#myGradient')",
                  },
                }}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{t('lbl_last_week_all_merchant_transactions')}</h4>
              
            </CardBody>            
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
