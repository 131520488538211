/*
   @page send notification
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import CustomInput from "components/CustomInput/CustomInput.js";
import { useTranslation } from 'react-i18next';


/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};
function getStyles(category, categoryID, theme) {
  return {
    fontWeight:
      categoryID.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

/*
  @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

/*
  @function log detail page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const {t} = useTranslation();
  const [serial_number, setSerialNumber] = useState("");
  const [msg, setMsg] = useState("");  
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  /*
    *Function for send notification
  */
  const sendNotification = () => {
    const data = {'message':msg,'serial_number':serial_number};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'send_notification', data, {
      headers: headers
    }).then(response => {
      setSuccessMsg(response.data.status);
      setSuccess(true);
      setSerialNumber("");
      setMsg("");
    }).catch(error => {
      return error;
    });
  } 
  
  const classes = useStyles();  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;  
  
/*
  @function close alert dialog
*/
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  var permissionData = JSON.parse(localStorage.getItem("admin_permission"));
  if(permissionData.notification == "0")
  {    
        return (
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
                <CardHeader className="cardheader" color="warning">
                  <h4 className={classes.cardTitleWhite}>{t('lbl_send_notification_message')}</h4>
                </CardHeader>
    
                <CardBody>
                  <GridContainer>
                  <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                    </h3>
  
                  </center>
                  <div id="transitInfo"></div>
                  </GridItem>
                  </GridContainer>
                </CardBody>
    
              </Card>
            </GridItem>
          </GridContainer>);
      
  }

  return (
    <GridContainer>   

      <GridItem xs={12} sm={12} md={12}>
        {/* 
          Display error message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
          Display success message
        */}
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_send_notification_message')}</h4>            
          </CardHeader>
          <CardBody>

          <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t('lbl_serial_number')}
                      id="m_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{maxLength: 24}}
                      
                      value={serial_number}
                      onChange={(e) => setSerialNumber(e.target.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t('lbl_notification_message')}
                      id="m_id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{maxLength: 24}}
                      
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                    />
                  </GridItem>
                </GridContainer>       

            <Button id="commonGreenBtn" onClick={() => { sendNotification() }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF',marginTop:"20px" }}>
              {t('lbl_send')}
            </Button>
          </CardBody>
        </Card>      

      
      </GridItem>
    </GridContainer>
  );
}
