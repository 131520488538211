import React, { useEffect, useState } from "react";
// Import Chartist and Chartist Graph


// @material-ui/core
import { makeStyles } from "@mui/styles";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { CategoryOutlined } from '@mui/icons-material';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { LineChart } from "@mui/x-charts/LineChart";

import {
  dailySalesChart,
  dailyTransChart,
} from "variables/charts.js"; // Import chart data and options

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

// Define styles
const useStyles = makeStyles(styles);

export default function Dashboard() {
  
  const { t } = useTranslation();
  const [totalTransaction, setTotalTransaction] = useState(null);
  const [totalAmt, setTotalAmt] = useState(null);
  const [totalCat, setTotalCat] = useState(null);
  const [totalTaxes, setTotalTaxes] = useState(null);
  const [totalItem, setTotalItem] = useState(null);
  
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [weeklyRevenueData, setWeeklyRevenueData] = useState([]);
  const [weeklyTransData, setWeeklyTransData] = useState([]);
  const [accessPage, setAccessPage] = useState(1);    
  const getDashboardDetail = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken'),
    };
    
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_dashboard_detail', data, { headers: headers })
      .then(response => {
        setTotalTransaction(response.data.totalTransaction);
        if(response.data.totalAmount > 1000000 )
        {
          setTotalAmt((response.data.totalAmount / 1000000).toFixed(2) + " M");
          
        }else if(response.data.totalAmount > 1000)
        {
          setTotalAmt((response.data.totalAmount / 1000).toFixed(2) + " K");
        }else{
          setTotalAmt((response.data.totalAmount/1).toFixed(2));
        }       
        setTotalCat(response.data.totalCategory);
        setTotalTaxes(response.data.totalTaxes);
        setTotalItem(response.data.totalItem);
        setLoadingDashboard(false);

        // Process the weekly chart data
        
        var weeklyRevenue = [];
        var weeklyTrans = [];
        response.data.weeklyChartData.forEach((obj, idx) => {
          weeklyRevenue.push({ day: obj.day, sales: obj.daily_total });
          weeklyTrans.push({ day: obj.day, sales: obj.total_rec });
        });
      
        setWeeklyRevenueData(weeklyRevenue);
        setWeeklyTransData(weeklyTrans);
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    const is_location_active = localStorage.getItem('is_active');
    if (is_location_active === "0") {
      window.location.href = "/in_active";
      return;
    }

    const permissionData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissionData && permissionData.access_to_online_dashboard === 0) {
      setAccessPage(0);
      return;
    }

    getDashboardDetail();
  }, []);

  const classes = useStyles();

  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>Dashboard</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                      <div>{t('msg_you_do_not_have_permission')}</div>
                      <div>{t('msg_please_contact_an_administrator')}</div>
                      <div>{t('msg_thank_you')}</div>
                    </h3>
                  </center>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  if (loadingDashboard) {
    return (
      <center>
        <h3>Loading...</h3>
        <CircularProgress id="loginProgress" />
      </center>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssessmentIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_transactions')}</p>
              <h3 className={classes.cardTitle}>
                {(totalTransaction !== null) ? totalTransaction : <CircularProgress />}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AttachMoneyIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_revenue')}</p>
              <h3 className={classes.cardTitle}>
                {(totalAmt !== null) ? "$" + totalAmt : <CircularProgress />}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <CategoryOutlined />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_categories')}</p>
              <h3 className={classes.cardTitle}>
                {(totalCat !== null) ? totalCat : <CircularProgress />}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DynamicFeedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>{t('lbl_total_items')}</p>
              <h3 className={classes.cardTitle}>
                {(totalItem !== null) ? totalItem : <CircularProgress />}
              </h3>
            </CardHeader>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              {/* <ChartistGraph
                className="ct-chart"
                data={weeklyRevenueData}
                type={Chartist.Line} // Use the Chartist.Line constructor here
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
               */}
              
              <LineChart
                height={400}
                xAxis={[{ scaleType: "band", data: weeklyRevenueData.map((d) => d.day) }]}
                series={[{ data: weeklyRevenueData.map((d) => d.sales), label: "Revenue", color: "#333333" }]}
                grid={{ vertical: true, horizontal: true }}
                margin={{ left: 50, right: 40, top: 50, bottom: 50 }}
                sx={{
                  '& .MuiLineElement-root': {
                    strokeDasharray: '1 1',
                    strokeWidth: 2,
                  },
                  '& .MuiAreaElement-series-Germany': {
                    fill: "url('#myGradient')",
                  },
                }}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{t('lbl_last_week_revenue')}</h4>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="warning">
              {/* <ChartistGraph
                className="ct-chart"
                data={weeklyTransData}
                type={Chartist.Line} // Use the Chartist.Line constructor here
                options={dailyTransChart.options}
                listener={dailyTransChart.animation}
              /> */}
              <LineChart
                height={400}
                xAxis={[{ scaleType: "band", data: weeklyTransData.map((d) => d.day) }]}
                series={[{ data: weeklyTransData.map((d) => d.sales), label: "Transactions", color: "#333333" }]}
                grid={{ vertical: true, horizontal: true }}
                margin={{ left: 50, right: 40, top: 50, bottom: 50 }}
                sx={{
                  '& .MuiLineElement-root': {
                    strokeDasharray: '1 1',
                    strokeWidth: 2,
                  },
                  '& .MuiAreaElement-series-Germany': {
                    fill: "url('#myGradient')",
                  },
                }}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>{t('lbl_last_week_transactions')}</h4>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
