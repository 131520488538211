/*
   @page Device manager
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import MUIDataTable from "mui-datatables";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SelectMui from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';


/*
  @const Page styles
*/
const styles = {
  root: {
    '& .Mui-selected': {
      backgroundColor: 'black',
      color: '#ffa726',
    }
  },
  rowPerPage:
  {
    minHeight: "40px",
    height: "40px"
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "white",
      background: "#ffa726"
    }
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  formControl: {
    margin: 1,
    minWidth: 120,
    maxWidth: 300,
  },
  popupHalfWidth: {
    width: "100%",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: 5,
  },
  setFieldHeight: {
    minHeight: "48px",
  },
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});
/*
  @function Device manager page logic
*/
const useStyles = makeStyles(styles);


export default function TableList() {
  /*
    variables start
  */
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
  const {t} = useTranslation();
  const [tableData, setTableData] = useState(false);  
  const [editKeyName, setEditKeyName] = useState("");
  const [editKeyLimit, setEditKeyLimit] = useState(0);
  const [editId, setEditId] = useState(0);
  
  const [keyName, setKeyName] = useState("");
  const [keyLimit, setKeyLimit] = useState(10);  
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [deleteKeyDialog, setKeyDeleteDailog] = React.useState(false);  
  const [id, setId] = React.useState(0);  
  const [rowPerPage, setRowPerPage] = useState(15);
  const [updateKeyDialog, setUpdateKeyDailog] = React.useState(false); 
  const [groupId, setGroupId] = useState([]);
  const [groupEditId, setGroupEditId] = useState([]);
  const [groupMenuList, setGroupMenuList] = useState({1:"MagicPay USA",2:"MagicPay Puerto Rico",3:"MagicPay US Virgin Islands",4:"MagicPay Mexico"});
  const [userGroup, setUserGroup] = React.useState([]);
  const [adminEmail, setAdminEmail] = useState("");


  var columns = "";

  if(adminEmail == "admin@magicpay.net")
  {
    columns = [
      {
        name: "ID",
        label: t('lbl_id'),
        options: {        
          sort: true,
          //hint: "Sort by ID",
        },
      },
      {
        name: "Name",
        label: t('lbl_name'),
        options: {        
          sort: true,
          //hint: "Sort by ID",
        },
      },
      {
        name: "Limit Per Day",
        label: t('lbl_limit_per_day'),
  
        options: {        
          sort: true,
          //hint: "Sort by MID",
        },
      },
      {
        name: "API Key",
        label: t('lbl_api_key'),
  
        options: {        
          sort: true,
          //hint: "Sort by MID",
        },
      },  
      {
        name: "Group",
        label: t('lbl_group'),
  
        options: {        
          sort: true,
          //hint: "Sort by MID",
        },
      },    
      {
        name: "Created By",
        label: t('lbl_created_by'),
        options: {
          filter: true,
          sort: true,
        //  hint: "Sort by Email",
        },
      },
      {
        name: "Created At",
        label: t('lbl_created_at'),
        options: {
          filter: true,
          sort: true,
        //  hint: "Sort by Email",
        },
      },
      {
        name: "Edit",
        label: t('lbl_edit'),
        options: {
          filter: true,
          sort: false,
        //  hint: "Sort by Email",
        },
      },
      {
        name: "Delete",
        label: t('lbl_delete'),
        options: {
          filter: true,
          sort: false,
        },
      },    
    ];
  }else{
    columns = [
      {
        name: "ID",
        label:  t('lbl_id'),
        options: {        
          sort: true,
          //hint: "Sort by ID",
        },
      },
      {
        name: "Name",
        label: t('lbl_name'),
        options: {        
          sort: true,
          //hint: "Sort by ID",
        },
      },
      {
        name: "Limit Per Day",
        label: t('lbl_limit_per_day'),
  
        options: {        
          sort: true,
          //hint: "Sort by MID",
        },
      },
      {
        name: "API Key",
        label: t('lbl_api_key'),
  
        options: {        
          sort: true,
          //hint: "Sort by MID",
        },
      },  
      {
        name: "Group",
        label: t('lbl_group'),
  
        options: {        
          sort: true,
          //hint: "Sort by MID",
        },
      },    
      {
        name: "Created At",
        label:  t('lbl_created_at'),
        options: {
          filter: true,
          sort: true,
        //  hint: "Sort by Email",
        },
      },
      {
        name: "Edit",
        label: t('lbl_edit'),
        options: {
          filter: true,
          sort: false,
        //  hint: "Sort by Email",
        },
      },
      {
        name: "Delete",
        label:  t('lbl_delete'),
        options: {
          filter: true,
          sort: false,
        },
      },    
    ];
  }
  
  const options = {
    selectableRows: 'none',  
    searchOpen: false,
    download: false,
    print: false,    
    filter: false,
    searchAlwaysOpen: false,   
  };
  
  const getApiKeyList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_api_key_list', data, {
      headers: headers
    }).then(response => { 
      var keyArr = [];
      response.data.forEach((eachKey) => {
        var grpIdarr = eachKey.group_id.split(",");
       var keyGrpName =  grpIdarr.map((id)=>{
       return groupMenuList[id];
        });
        if(localStorage.getItem('admin_email') == "admin@magicpay.net")
        {
          keyArr.push([eachKey.id,          
            eachKey.apikey_name,
            eachKey.apikey_limit,
            <div> {"**************************"+eachKey.apikey.substring(eachKey.apikey.length - 4)}
            
            <i style={{marginLeft:"15px",cursor:"pointer",display:"inline"}} onClick={(e) => {navigator.clipboard.writeText(eachKey.apikey);setSuccessMsg(t('lbl_api_key_copied'));setSuccess(true); }} class="fa fa-clone" aria-hidden="true"></i></div>,
            keyGrpName.join(", "),
            eachKey.email,
            eachKey.created_at,
            <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => {setUpdateKeyDailog(true);setEditKeyName(eachKey.apikey_name);setEditKeyLimit(eachKey.apikey_limit);setEditId(eachKey.id);setGroupEditId(eachKey.group_id.split(','));}}><EditIcon style={{ height: '20px' }}></EditIcon></button>,
            <button id="reddeleteicon" style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { setKeyDeleteDailog(true); setId(eachKey.id);}} ><DeleteForeverIcon></DeleteForeverIcon></button>,
          ]);
        }else
        {
          keyArr.push([eachKey.id,          
            eachKey.apikey_name,
            eachKey.apikey_limit,
            <div> {"**************************"+eachKey.apikey.substring(eachKey.apikey.length - 4)}
           
            <i style={{marginLeft:"15px",cursor:"pointer",display:"inline"}} onClick={(e) => {navigator.clipboard.writeText(eachKey.apikey);setSuccessMsg(t('lbl_api_key_copied'));setSuccess(true); }} class="fa fa-clone" aria-hidden="true"></i></div>,
            keyGrpName.join(", "),
            eachKey.created_at,
            <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => {setUpdateKeyDailog(true);setEditKeyName(eachKey.apikey_name);setEditKeyLimit(eachKey.apikey_limit);setEditId(eachKey.id);setGroupEditId(eachKey.group_id.split(','));}}><EditIcon style={{ height: '20px' }}></EditIcon></button>,
            <button id="reddeleteicon" style={{ backgroundColor: '#FF9800', color: '#FFFFFF', border: 'none' }} onClick={() => { setKeyDeleteDailog(true); setId(eachKey.id);}} ><DeleteForeverIcon></DeleteForeverIcon></button>,
          ]);
        }
       
      });
      setTableData(keyArr);
      
    }).catch(error => {
      return error;
    });
  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  const handleDeleteId = () => {
    
    const data = {
      id:id
    };
    
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'delete_api_key', data, {
      headers: headers
    }).then(response => {
      setId(0);
      setKeyDeleteDailog(false);
      setSuccess(true);
      setSuccessMsg(t('msg_api_key_deleted_successfully'));
      getApiKeyList();
    }).catch(error => {
      getApiKeyList();
      setKeyDeleteDailog(false);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }

  const createKey = (action) => {
    let data = ""
    
    
    if(action == 'add')
    {
        if(keyName == "")
        {
          setError(true);
          setErrorMsg(t('msg_please_enter_name'));
         return;
        }
        if(keyLimit == "" || keyLimit == "0")
        {
          setError(true);
          setErrorMsg(t('msg_please_enter_limit'));
         return;
        }
        if(groupId == "")
        {
          setError(true);
          setErrorMsg(t('msg_please_select_group'));
          return;
        }
       data = {
        apikey_name: keyName,
        apikey_limit: keyLimit,
        group_id:groupId.join(",")
      };
    }
    if(action == 'edit')
    {
       if(editKeyName == "")
        {
          setError(true);
          setErrorMsg(t('msg_please_enter_name'));
         return;
        }
        if(editKeyLimit == "" || editKeyLimit == "0")
        {
          setError(true);
          setErrorMsg(t('msg_please_enter_limit'));
         return;
        }
        if(groupEditId == "")
        {
          setError(true);
          setErrorMsg(t('msg_please_select_group'));
          return;
        }
       data = {
        apikey_name: editKeyName,
        apikey_limit: editKeyLimit,
        group_id:groupEditId.join(","),
        id:editId
      };
    }
    
    
    setTableData(false);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'create_key', data, {
      headers: headers
    }).then(response => {
      if(action == 'add')
      {
        setKeyLimit(10);
        setKeyName("");
        setGroupId([]);
        setSuccess(true);
        setSuccessMsg(t('msg_api_key_created_successfully'));
      }
      if(action == 'edit')
      {
        setEditKeyLimit(0);
        setEditKeyName("");
        setGroupEditId([]);
        setEditId(0);        
        setSuccessMsg(t('msg_api_key_updated_successfully'));
        setUpdateKeyDailog(false);
      }
      setSuccess(true);
      getApiKeyList();
    }).catch(error => {
      getApiKeyList();
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }

  const handleChange = (event,action) => {
    const {
      target: { value },
    } = event;
    if(action == "add")
    {
      setGroupId(        
        typeof value === 'string' ? value.split(',') : value,
      );
    }if(action == "edit")
    {
      setGroupEditId(    
        typeof value === 'string' ? value.split(',') : value,
      );
    }

    
    console.log("---------------");
    console.log(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    setAdminEmail(localStorage.getItem('admin_email'));
    var groupID = localStorage.getItem('group_id');        
    if(groupID != "0" && groupID != "" && groupID != null)    {      
       const allowGroupIdArr = groupID.split(","); 
       var arr = {};
       allowGroupIdArr.forEach((value, key) => {
        arr[value] = groupMenuList[value];
      });        
      setGroupMenuList(arr);
    }
    if (!tableData) {
      getApiKeyList();
    }    

  }, []);

 
  
 
  var permissionData = JSON.parse(localStorage.getItem("admin_permission"));  
  if(permissionData.access_api_key == "0")
  {
    
      return (
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              <CardHeader className="cardheader" color="warning">
                <h4 className={classes.cardTitleWhite}>{t('lbl_security_key_list')}</h4>
              </CardHeader>
  
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                    </h3>
  
                  </center>
                  <div id="transitInfo"></div>
                  </GridItem>
                </GridContainer>
              </CardBody>
  
            </Card>
          </GridItem>
        </GridContainer>);
    
  }
  if (!tableData) { //Check if device data not come than display loading dialog
    return (
      <GridContainer>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_security_key_list')}</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t('msg_loading')}</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
    
     

      {/* 
        Delete Device  popup start
       */}
      <Dialog
        open={deleteKeyDialog}
        keepMounted
        onClose={deleteKeyDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_api_key')}</DialogTitle>
        <DialogActions>
          <Button id="btnGreyCancel" onClick={() => { setKeyDeleteDailog(false);setId(0); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_no')}
          </Button>
          <Button id="btnRedDelete" onClick={()=>{ handleDeleteId(); }} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
          </Button>
        </DialogActions>
      </Dialog>

    
      {/* Clear Device  data popup start */}
      <GridItem xs={12} sm={12} md={12}>

        {/* 
            Display error message
          */}
        <Snackbar id="popuperror" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMsg}
          </Alert>
        </Snackbar>

        {/* 
            Display success message
          */}
        <Snackbar id="popupsuccess" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_security_key_list')}</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
          
            <GridContainer>
              {/* Select start date and end date */}

              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label={t('lbl_name')}
                    onChange={(e) => { setKeyName(e.target.value) }}
                    value={keyName}

                  />
                </FormControl>
              </GridItem>   
              <GridItem xs={12} sm={12} md={3}>
                <div style={{ height: "25px" }}>&nbsp;</div>
                <FormControl style={{ width: "100%" }}>
                  {/* Amount from textfield */}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    margin="full"
                    label={t('lbl_limit_per_day')}
                    onChange={(e) => { setKeyLimit(e.target.value) }}
                    value={keyLimit}

                  />
                </FormControl>
              </GridItem> 
              <GridItem xs={12} sm={12} md={3} >
              <div style={{ height: "25px" }}>&nbsp;</div>
              <FormControl style={{ width: "100%",margin:"0px !important" }} sx={{ m: 0, width: "100%" }}>
                  {/* Amount to textfield */}
                  <InputLabel id="demo-simple-select-helper-label">Group</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select"
                    label={t('lbl_group')}
                    multiple
                    style={{ width: '100%' }}
                    value={groupId}
                    onChange={(e) => {handleChange(e,'add')}}
                    input={<OutlinedInput label="Group" />}
                    MenuProps={MenuProps}
                  >
                     {
                        Object.entries(groupMenuList).map(([key, value]) => (
                            <MenuItem key={key} value={key} >
                              {value}
                            </MenuItem>
      
                          ))
                       }  
                  </Select>
                </FormControl>
                  </GridItem>  
              <GridItem xs={12} sm={12} md={3}>
                <center>
                  <div style={{ height: "25px" }}>&nbsp;</div>
                  <FormControl style={{ with: '100%' }} className={classes.popupHalfWidth} xs={12} sm={12} md={12}>
                    <Button variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} onClick={()=>{ createKey('add'); }} className={classes.button}>
                      {t('lbl_add')}
                    </Button>
                  </FormControl>
                </center>

              </GridItem>
         
             
            </GridContainer>
            <div id="deviceListtable" class="deviceListtable">           
                <MUIDataTable
              id="merchantListTable"              
              data={tableData}
              columns={columns}
              options={options}
            />
            </div>       
          </CardBody>
        </Card>
      </GridItem>

        {/* 
          Update Security Key start 
        */}
        <Dialog open={updateKeyDialog} onClose={() => { setUpdateKeyDailog(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('lbl_update_security_key')}</DialogTitle>
          <DialogContent>


            <GridContainer>
            <GridItem xs={12} sm={12} md={12} >
            <div style={{ height: "25px" }}>&nbsp;</div>
            <TextField
              margin="full"
              id="outlined-basic"
              label={t('lbl_name')}
              fullWidth
              variant="outlined"
              value={editKeyName}
              onChange={(e)=>{setEditKeyName(e.target.value);}}
            />

            </GridItem>
              <GridItem xs={12} sm={12} md={12} >
              <div style={{ height: "25px" }}>&nbsp;</div>
              <TextField
                  margin="full"
                  id="outlined-basic"
                  label={t('lbl_limit_per_day')}
                  fullWidth
                  variant="outlined"
                  value={editKeyLimit}
                  onChange={(e)=>{setEditKeyLimit(e.target.value);}}
                  inputProps={{ min: 0 }}
                />

              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
              <div style={{ height: "25px" }}>&nbsp;</div>
              <FormControl style={{ width: "100%",margin:"0px !important" }} sx={{ m: 0, width: "100%" }}>
                  {/* Amount to textfield */}
                  <InputLabel id="demo-simple-select-helper-label">{t('lbl_group')}</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select"
                    label={t('lbl_group')}
                    multiple
                    style={{ width: '100%' }}
                    value={groupEditId}
                    onChange={(e) => {handleChange(e,'edit')}}
                    input={<OutlinedInput label="Group" />}
                    MenuProps={MenuProps}
                  >
                     {
                          Object.entries(groupMenuList).map(([key, value]) => (
                            <MenuItem key={key} value={key} >
                              {value}
                            </MenuItem>
      
                          ))
                       }  
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setUpdateKeyDailog(false);setEditKeyLimit(0);setEditKeyName("");setEditId(0); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenAdd"  onClick={()=>{createKey('edit')}}  color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_update_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>

    </GridContainer>
  );
}
