/*
  @page Category list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';       
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormControl from '@mui/material/FormControl';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});
const useStyles = makeStyles(styles);

/*
  @function category list page logic
*/
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [originalTableData, setOrignalTableData] = useState(false);  
  const [searchQuery, setSearchQuery] = useState('');  
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [catId, setCatId] = useState("");
  const [catDeleteDailog, setCatDeleteDailog] = useState(false);
  const [accessPage, setAccessPage] = useState(1);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });

  const localeText = {
    toolbarColumns: t('lbl_Columns'), 
    toolbarDensity: t('lbl_Density'),     
    paginationRowsPerPage: "testtt name change",
  };
  /*
    @function Popup close
  */
    const handleSearch = (e) =>
    {  
      setSearchQuery(e.target.value);  
      const filteredData = originalTableData.filter((row) =>
        Object.values(row).some(
          (value) =>
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
      setTableData(filteredData);
    }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function Get category list
  */
  const getCategoryList = () => {
    const data = {};
    const headers = { 'Authorization': 'Bearer ' + localStorage.getItem('merchantToken') }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_add_form_detail', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.catData.forEach((eachCategory) => {

        let itemCategory = {}
        itemCategory["Id"] = eachCategory.id;
        itemCategory["Category_Name"] = eachCategory.name;
        itemCategory["Edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachCategory.id, eachCategory.name)}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        itemCategory["Delete"] = <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setCatId(eachCategory.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([eachCategory.id, eachCategory.name, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachCategory.id, eachCategory.name)}><EditIcon style={{height:'20px'}}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setCatId(eachCategory.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(itemCategory);
      });
      setTableData(userArr);
      setOrignalTableData(userArr);

    }).catch(error => {
      return error;
    });
  }
  const columns = [
    {
      field: 'Id', 
      headerName: t('lbl_id'), 
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Category_Name',
      headerName: t('lbl_category_name'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Edit',
      headerName: t('lbl_edit'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Delete',
      headerName: t('lbl_delete'),
      flex: 1,
      renderCell: (params) => params.value
    },
  ];


  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissonData) {
      if (permissonData.access_item_catalog === 0 || permissonData.access_to_online_dashboard === 0) {
        setAccessPage(0);
        return;
      }
    }

    getCategoryList();
  }, []);


  /*
    @function to set catid and catname for edit category
  */
  const handleEditCat = (id, cat_name) => {
    setCatId(id);
    setCatName(cat_name);
    setOpenAddCategory(true);
  }

  /*
    @function For delete category
  */
  const handleDeleteCat = () => {
    setTableData(false);
    setCatDeleteDailog(false);
    const data = { id: catId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_item_cat', data, {
      headers: headers
    }).then(response => {
      getCategoryList();
      setCatId(0);
      if (response.data == 1) {
        setSuccess(true);
        setSuccessMsg(t('msg_category_deleted_successfully'));
      } else {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
      }
    }).catch(error => {
      getCategoryList();
      setCatId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });
  }

  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [catName, setCatName] = React.useState("");

  const handleCatNameChange = (event) => {
    const result = event.target.value.replace(/[^a-z1-9 ]/gi, '');
    setCatName(result)
  }


  /*
    @function For save category
  */
  const saveCategory = (id, category_name) => {
    setOpenAddCategory(false);
    setTableData(false);
    const data = { id: id, name: category_name };
    console.log(data);
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_item_cat', data, {
      headers: headers
    }).then(response => {
      setSearchQuery("");
      setCatId(0);
      setCatName("");
      getCategoryList();
      console.log(response)
      if (response.data.Error == 1) {
        setError(true);
        setErrorMsg(t('msg_duplicate_category_not_accepted'));
      } else {
        if (id == 0) {
          setSuccess(true);
          setSuccessMsg(t('msg_category_added_successfully'));
        }
        else {
          setSuccess(true);
          setSuccessMsg(t('msg_category_updated_successfully'));
        }
      }

    }).catch(error => {
      console.log(error);
      getCategoryList();
      setCatId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }


  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_category_list')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come from api than display loading progress bar
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_category_list')}</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t('lbl_loadind_category')}</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>

      {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>


      {/* 
        Display success message
      */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_category_list')}</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
             <GridContainer>
             <GridItem xs={12} sm={12} md={6} >
             <TextField
                        style={{ width: '100%' }}
                        label={t('lbl_search')}
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => {handleSearch(e) }}
                      />
              </GridItem>
            <GridItem xs={12} sm={12} md={6}>   

                      <FormControl style={{width:"100%"}}>
                      <Button
                        id="btnGreenAdd"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        style={{ backgroundColor: '#ffa726', color: '#FFFFFF',float:'right',width:"50px",height:'55px',position:"absolute",right:"0" }}
                        onClick={() => { setOpenAddCategory(true) }}
                      >+</Button>
                      </FormControl>                   
              </GridItem>

            {/* 
              *Display category list
            */}
                         
            <GridItem xs={12} sm={12} md={12} style={{marginBottom:"50px"}}>
              <div id="categoryListTable">
              <DataGrid
              
              style={{marginTop:"20px"}}
                getRowId={(row) => row.Id}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                slots={{ toolbar: CustomToolbar }}
                rows={tableData}
                columns={columns}
                localeText={localeText}
                disableRowSelectionOnClick

              /> 
              </div>
            </GridItem>          
            </GridContainer>

            {/* <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Category Name", "Edit", "Delete"]}
              tableData={tableData}
            /> */}
          </CardBody>
        </Card>

        {/* Add category popup start */}
        <Dialog open={openAddCategory} onClose={() => { setOpenAddCategory(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(catId == 0) ? t('lbl_add_new') : t('lbl_update_smallcase')}{" "+t('lbl_categoty')}</DialogTitle>
          <DialogContent>
            <TextField
              style={{marginTop:"8px"}}
              margin="full"
              variant="outlined"
              id="name"
              label={t('lbl_category_name')}
              type="email"
              onChange={handleCatNameChange}
              value={catName}
              fullWidth

            // setCatName(e.target.value)
            />
          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setCatId(0); setCatName(""); setOpenAddCategory(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenUpdate" onClick={() => { saveCategory(catId, catName) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(catId == 0) ? t('lbl_add_smallcase') : t('lbl_update_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Add category popup end */}

        {/* Delete category popup start*/}
        <Dialog
          open={catDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >

          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_category')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setCatId(0); setCatDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteCat} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete ctegory  popup end*/}
      </GridItem>
    </GridContainer>
  );
}
