/*
  @page Tax list
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Helper from "./../../util/Helper";
import Box from '@mui/material/Box';       
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import SelectMui from "@mui/material/Select";
import InputLabelMui from "@mui/material/InputLabel";
import FormControlMui from "@mui/material/FormControl";

function CustomToolbar() {
  return (
    <GridToolbarContainer>      
       <Box sx={{ flexGrow: 1 }} />
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }}  />  */}
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarFilterButton /> */}

    </GridToolbarContainer>
  );
}

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  taxTypeLabel: {
    fontSize: "13px",
    marginTop: "8px",
  },
};

/*
  @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

/*
  @function Tax listing page logic
*/
const useStyles = makeStyles(styles);
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [taxId, setTaxId] = useState("");
  const [catDeleteDailog, setCatDeleteDailog] = useState(false);
  const [stateName, setStateName] = useState("");
  const [isBtnDisable, setBtnDisable] = useState(false);
  const [accessPage, setAccessPage] = useState(1);

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
  });
  const localeText = {
    toolbarColumns: t('lbl_Columns'), 
    toolbarDensity: t('lbl_Density'),     
  };


  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
  };

  /*
    @function For get tax list data
  */
  const getTaxList = () => {
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'get_merchant_taxes', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      setStateName(response.data.stateName);
      let cnt = 0;
      response.data.taxesData.forEach((eachTax) => {
        var taxesAmt = "";
        if (eachTax.taxes_type == "Flat Amount") { //Check tax type was flat amount
          taxesAmt = Helper.viewCurrencyFormat(parseFloat(eachTax.taxes_amt));
        }
        else {
          // taxesAmt = Helper.viewPercentageFormat(parseFloat(eachTax.taxes_amt));
          var lastDigit = parseFloat(eachTax.taxes_amt).toFixed(3).toString().slice(-1);
          if (lastDigit === "0") {
            taxesAmt = parseFloat(eachTax.taxes_amt).toFixed(2) + "%";
          } else {
            taxesAmt = parseFloat(eachTax.taxes_amt).toFixed(3) + "%";
          }

        }

        if (eachTax.name === "City Tax" || eachTax.name === "State Tax" || eachTax.name === "Reduced State Tax") //Check tax name was city tax,state tax or reduced state tax
        {
          cnt++;
        }

        let taxListData = {}
        taxListData["Id"] = eachTax.id;
        taxListData["Name"] = eachTax.name;
        taxListData["Type"] = (eachTax.taxes_type == "Flat Amount")?t('lbl_flat_amount'):t('lbl_percentage');
        taxListData["Value"] = taxesAmt;
        taxListData["Edit"] = <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachTax.id, eachTax.name, eachTax.taxes_type, eachTax.taxes_amt)}><EditIcon style={{ height: '20px' }}></EditIcon></button>;
        taxListData["Delete"] = <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setTaxId(eachTax.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>;

        // userArr.push([eachTax.id, eachTax.name, eachTax.taxes_type, taxesAmt, <button id="greenediticon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => handleEditCat(eachTax.id, eachTax.name, eachTax.taxes_type, eachTax.taxes_amt)}><EditIcon style={{ height: '20px' }}></EditIcon></button>, <button id="reddeleteicon" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', border: 'none' }} onClick={() => { setTaxId(eachTax.id); setCatDeleteDailog(true); }}><DeleteForeverIcon></DeleteForeverIcon></button>]);
        userArr.push(taxListData);
      });
      if (cnt === 3) //Check if count is equal to 3 than disable button
      {
        setBtnDisable(true);
      } else if (cnt === 0) { //Check if count is equal to 0 than enable button
        setBtnDisable(false);
      }
      setTableData(userArr);

    }).catch(error => {
      return error;
    });
  }

  const columns = [
    {
      field: 'Id', 
      headerName: t('lbl_id'), 
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Name',
      headerName: t('lbl_name'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Type',
      headerName: t('lbl_type'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Value',
      headerName: t('lbl_value'),
      flex: 1,
      renderCell: (params) => params.value,

    },
    {
      field: 'Edit',
      headerName: t('lbl_edit'),
      flex: 1,
      renderCell: (params) => params.value
    },
    {
      field: 'Delete',
      headerName: t('lbl_delete'),
      flex: 1,
      renderCell: (params) => params.value
    },
  ];

  useEffect(() => {
    var is_location_active = localStorage.getItem('is_active');    
    if(is_location_active === "0")
    {      
      window.location.href = "/in_active";
      return;  
    }
    const permissonData = JSON.parse(localStorage.getItem('permissonData'));

    if (permissonData) {
      if (permissonData.access_tax === 0 || permissonData.access_to_online_dashboard === 0) {
        setAccessPage(0);
        return;
      }
    }
    getTaxList();

  }, []);

  /*
    @function For handle edit tax
  */
  const handleEditCat = (id, name, type, amt) => {
    setTaxId(id);
    setTaxName(name);
    setTaxType(type);

    if (type == "Flat Amount") //Check if tax type was flat amount
    {
      var taxamt = amt.replace(",","");
    }
    else {
      var taxamt = amt;
    }

    setTaxAmount(taxamt);
    setOpenAddCategory(true);
  }

  /*
    @function For handle delete tax
  */
  const handleDeleteCat = () => {
    setTableData(false);
    setCatDeleteDailog(false);
    const data = { id: taxId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'delete_merchant_taxes', data, {
      headers: headers
    }).then(response => {
      getTaxList();
      setTaxId(0);
      if (response.data == 1) { //Check if data was deleted or not
        setSuccess(true);
        setSuccessMsg(t('msg_tax_deleted_successfully'));
      } else {
        setError(true);
        setErrorMsg(t('msg_some_error_occur'));
      }
    }).catch(error => {
      getTaxList();
      setTaxId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }

  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [taxName, setTaxName] = React.useState("");
  const [taxType, setTaxType] = React.useState("Percentage");
  const [taxAmount, setTaxAmount] = React.useState();

  /*
    @function For tax value validation
  */
  const validateTaxValue = (e) => {
    var val = e.target.value;
    if (val.length < 7) { //Check value was less then 6
      var per = val.replace(".", '');
      per = per / 1000;
      if (!isNaN(per)) {
        setTaxAmount(parseFloat(per).toFixed(3));
      }


    }
  }

  /*
    @function For empty all tax field
  */
  const emptyAllField = () => {
    setTaxId(0);
    setTaxName("");
    setTaxType("Percentage");
    setTaxAmount();
  }

  /*
    @function For add extra taxes
  */
  const addExtraTax = () => {
    var addArr = [
      ['City Tax', 1],
      ['State Tax', 10.50],
      ['Reduced State Tax', 6]
    ];
    // for (var i = 0; i < addArr.length; i++) {
    //   var eachTax = addArr[i];

    //   setOpenAddCategory(false);
    //   setTableData(false);


    // }

    // const data = { id: 0, name: eachTax[0], taxes_type: taxType, taxes_amt: eachTax[1] };
    const data = {};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_puerto_rico_data', data, {
      headers: headers
    }).then(response => {
      setSuccess(true);
      setSuccessMsg(t('msg_tax_added_successfully'));

    }).catch(error => {
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

    setTimeout(function () {
      getTaxList();
    }, 2000);

  }

  /*
    @function For save tax
  */
  const saveTax = (id) => {
    setOpenAddCategory(false);
    setTableData(false);
    const data = { id: id, name: taxName, taxes_type: taxType, taxes_amt: taxAmount };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('merchantToken')
    }
    data.parent_id = localStorage.getItem('parent_id');
    data.location_id = localStorage.getItem('location_id');
    axios.post(window.apiUrl + 'add_merchant_taxes', data, {
      headers: headers
    }).then(response => {
      setTaxId(0);
      setTaxName("");
      setTaxType("Percentage");
      setTaxAmount();
      getTaxList();
      if (id == 0) { //Check if tax id was 0 than add tax and if not 0 than update tax
        setSuccess(true);
        setSuccessMsg(t('msg_tax_added_successfully'));

      }
      else {
        setSuccess(true);
        setSuccessMsg(t('msg_tax_updated_successfully'));

      }

    }).catch(error => {
      getTaxList();
      setTaxId(0);
      setError(true);
      setErrorMsg(t('msg_some_error_occur'));
      return error;
    });

  }
  let extraTaxAdd = "";
  if (stateName === "Puerto Rico") //Check id state was puerto rico
  {
    let btnStyle = { backgroundColor: '#ffa726', color: '#FFFFFF' };
    if (isBtnDisable) //Check button was disable
    {
      btnStyle = { backgroundColor: '#D3D3D3', color: '#FFFFFF', cursor: 'not-allowed !important' };
    }
    extraTaxAdd = <span style={{ marginLeft: "10px" }}><Button
      id="btnGreenAdd"
      variant="contained"
      color="primary"
      className={classes.button}
      disabled={isBtnDisable}
      style={btnStyle}
      onClick={() => { addExtraTax() }}>{t('lbl_puerto_rico_tax_rates')}</Button></span>;
  }


  if (accessPage === 0) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_taxes')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={12} md={12}>
                <center>
                  <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                  </h3>
                  
                </center>
                </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  }

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_taxes')}</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <center>
              <h3>{t('msg_loading')}</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>

      {/* 
          Display error message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>


      {/* 
          Display success message
        */}
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_taxes')}</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            <Button
              id="btnGreenAdd"
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}
              onClick={() => { emptyAllField(); setOpenAddCategory(true) }}
            >+</Button>
            {extraTaxAdd}
            {/* 
              * Display tax list
            */}
            <div id="taxListTable">

              <DataGrid
                style={{ marginTop: "20px" }}
                getRowId={(row) => row.Id}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                slots={{ toolbar: CustomToolbar }}
                rows={tableData}
                columns={columns}
                disableRowSelectionOnClick
                localeText={localeText}
              />

              {/* <Table
                id="taxListTable"
                className="taxListTable"
                class="taxListTable"
                tableHeaderColor="primary"
                tableHead={["ID", "Name", "Type", "Value", "Edit", "Delete"]}
                tableData={tableData}
              /> */}
            </div>
          </CardBody>
        </Card>
        {/* 
          Add tax form start 
        */}
        <Dialog open={openAddCategory} onClose={() => { setOpenAddCategory(false) }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{(taxId == 0) ? t('lbl_add_new') : t('lbl_update_smallcase')}{" "+t('lbl_tax')}</DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              margin="full"
              id="name"
              style={{marginTop:"8px"}}
              label={t('lbl_tax_name')}
              onChange={(e) => setTaxName(e.target.value.replace(/[^a-z1-9 ]/gi, ''))}
              value={taxName}
              fullWidth

            />

            <GridContainer>
              <GridItem xs={12} sm={12} md={6} style={{marginTop:"20px"}}>
                <FormControlMui style={{width:"100%"}}>
                <InputLabelMui id="demo-simple-select-label" >{t('lbl_tax_type')}</InputLabelMui>
                <SelectMui
                  style={{ width: "100%" }}
                  autosize={true}
                  label={t('lbl_tax_type')}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => setTaxType(e.target.value)}
                  value={taxType}
                >
                  <MenuItem value={"Percentage"}>{t('lbl_percentage')}</MenuItem>
                  <MenuItem value={"Flat Amount"}>{t('lbl_flat_amount')}</MenuItem>
                </SelectMui>
                </FormControlMui>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{marginTop:"20px"}}>

                <TextField
                  // autoFocus
                  // dir="rtl"
                  variant="outlined"
                  margin="full"
                  id="taxAmtInput"
                  label={t('lbl_tax_value')}
                  onChange={(e) => (taxType == "Flat Amount") ? setTaxAmount(Helper.currencyFormatWithDot(e, 'taxAmtInput')) : validateTaxValue(e)}
                  value={(taxType == "Flat Amount") ? taxAmount : (taxAmount != null) ? parseFloat(taxAmount).toFixed(3) : taxAmount}
                  fullWidth
                  // inputProps={{ min: 0, style: { textAlign: 'right' } }}
                  inputProps={{ min: 0 }}
                />

              </GridItem>
            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setTaxId(0); setTaxName(""); setOpenAddCategory(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenAdd" onClick={() => { saveTax(taxId, taxName) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {(taxId == 0) ? t('lbl_add_smallcase') : t('lbl_update_smallcase')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* 
          Delete ctegory  popup start
        */}
        <Dialog
          open={catDeleteDailog}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{t('msg_delete_tax')}</DialogTitle>
          <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setTaxId(0); setCatDeleteDailog(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_no')}
            </Button>
            <Button id="btnRedDelete" onClick={handleDeleteCat} color="secondery" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}