/*
   @page Main login
*/

//Import require libraries

import React, { useState,useEffect,useRef  } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from 'axios';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import LogoPath from "assets/img/Logo.png";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import { color, display, fontSize, fontWeight, height, margin, padding, styled, textAlign } from '@mui/system';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


/*
   @function print copyright text at bottom
*/
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('lbl_copyright')} © {" "}
      <Link color="inherit" href="https://magicpay.net/">
       MagicPay Merchant Services LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <br />
      <Link href="https://magicpay.net/terms/">{t('lbl_terms_of_use')}</Link>
    </Typography>
  );
}

/*
   @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});


/*
   @const Page styles
*/
const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
  
}));


/*
   @function Login page logic
*/

export default function LoginPage() {
    const {t,i18n} = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [secureEmail, setSecureEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loginError, setLoginError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [sucess, setSuccess] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [varificationBtnDisabled, setVarificationBtnDisabled] = useState(true);
  const [resendBtnDisabled, setResendBtnDisabled] = useState(false);
  const [verifyButtonText, setVerifyButtonText] = useState(t('lbl_webhook_verify'));
 
  const queryParams = new URLSearchParams(window.location.search);  
  const varifyBtnRef = useRef();
  localStorage.setItem('domain', "web14......");
  useEffect(() => {

    const currentLanguage = i18n.language;
    setSelectedLanguage(currentLanguage);

    if(queryParams.get('arg1') !== null && queryParams.get('arg2') !== null && queryParams.get('arg3') !== null && queryParams.get('arg1') !== "" && queryParams.get('arg2') !== "" && queryParams.get('arg3') !== "")
    {      
      const data = { email: atob(queryParams.get('arg1')), password: atob(queryParams.get('arg2')),merchant_id: atob(queryParams.get('arg3'))};
      axios.post(window.apiUrl + 'login_admin_and_merchant', data)
        .then(response => {
          console.log("------------------");
              console.log(response);
              console.log("-------------------");
          document.getElementById("loginProgress").style.display = "none";
          
          if (response.data.error === "UnAuthorised") { //Check username & password valid or not
            alert("invalid user name and password");
          }
        
          localStorage.setItem('token', response.data.admin_token);
          localStorage.setItem('merchantToken', response.data.merchant_token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('id', response.data.id);
          localStorage.setItem('parent_id', response.data.parent_id);
          localStorage.setItem('location_id', response.data.location_id);            
          if(localStorage.getItem("merchantToken") !== null)
          {
            window.location.href = "/dashboard";
          }
          
    
        }).catch(error => {
          document.getElementById("loginProgress").style.display = "none";
          if (error.response.data.activateError) { 
            setErrorMsg(error.response.data.activateError);
          } else {
            setErrorMsg(t('msg_Invalid_eMail_or_Password'));
          }
          setLoginError(true);
          return error;
        });       
         
    }
   else if(queryParams.get('arg1') !== null && queryParams.get('arg2') !== null && queryParams.get('arg1') !== "" && queryParams.get('arg2') !== "")
    {
    var emailCheck;  	
    emailCheck = atob(queryParams.get('arg1'));   
    const data = { email: atob(queryParams.get('arg1')), password: atob(queryParams.get('arg2')) };
    axios.post(window.apiUrl + 'login', data)
      .then(response => {
        document.getElementById("loginProgress").style.display = "none";
        
        if (response.data.error === "UnAuthorised") { //Check username & password valid or not
          alert(t('lbl_invalid_username_password'));
        }
        if(response.data.permission_data)
        {
          localStorage.setItem('permissonData', JSON.stringify(response.data.permission_data));
          
        }
        if (response.data.token) {
          setToken(response.data.token);
          if (response.data.role == "4") { //Check user was admin or merchant

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('group_id', response.data.group_id);
            if(localStorage.getItem("token") !== "token")
            {
              window.location.href = "/admin/dashboard";
            }
            
          } else {
            
            localStorage.setItem('merchantToken', response.data.token);
            localStorage.setItem('role', 'merchant');
            localStorage.setItem('id', response.data.id);
            localStorage.setItem('parent_id', response.data.parent_id);
            localStorage.setItem('location_id', response.data.location_id);           
            if(localStorage.getItem("merchantToken") !== null)
            {
              window.location.href = "/dashboard";
            }
            
          }
  
        }
  
      }).catch(error => {
        document.getElementById("loginProgress").style.display = "none";
        if (error.response.data.activateError) { 
          setErrorMsg(error.response.data.activateError);
        } else {
          setErrorMsg(t('msg_Invalid_eMail_or_Password'));
        }
        setLoginError(true);
        return error;
      });
      
    }
    
  }, []);  
  

  /*
    @function form validation
    check email & password not blank
  */
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  /*
   @function Popup close
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setLoginError(false);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);  
    setSelectedLanguage(lang);
    const currentPage = location.pathname;      
    const roloadPagesArr = ['/admin/table', '/admin/device_manager','/admin/incorrect_billing_amount_Kushki','/admin/admin_employees'];
    if( roloadPagesArr.includes(currentPage)){    
      window.location.reload();
    }
  };
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const [loginData, setLoginData] = useState(false);

  const sendCodeToSMS = (event) =>
  {
    event.preventDefault();
    const verificationData = {
      email: email      
    };

    axios.post(window.apiUrl + 'send_code_to_sms', verificationData)
    .then(response => {
      if(response.data.error)
      {
          setLoginError(true);
          setErrorMsg(response.data.error);
      }else{
        setSuccess(true);
        setSuccessMsg(t('lbl_webhook_msg_send_to_mobile_number')+" ******"+response.data.lastMobileDigit);
      }
      
    }).catch(error => {        
      setLoginError(true);
      setErrorMsg(t('lbl_webhook_msg_sms_error'));
    });

  }
  const resendVerificationCode = (event) =>
  {
    setResendBtnDisabled(true);
    event.preventDefault();
    const verificationData = {
      email: email      
    };

    axios.post(window.apiUrl + 'send_code', verificationData)
    .then(response => {
      setResendBtnDisabled(false);
    }).catch(error => {        
      setLoginError(true);
      setErrorMsg(t('lbl_webhook_msg_error_verify_code'));
      setResendBtnDisabled(false);
    });
  }
  const handleVerificationSubmit = (event) => {
    event.preventDefault();
    setVerifyButtonText(t('lbl_webhook_verifying'))
    setVarificationBtnDisabled(true)

    console.log("CODE="+code);
    console.log("gggggg"+code.join(''));
    

    const verificationData = {
      email: email,
      verificationCode: code.join('')
    };
  
    // Send the entered verification code to the backend for validation
    axios.post(window.apiUrl + 'verify_code', verificationData)
      .then(response => {
        setVerifyButtonText(t('lbl_webhook_verify'))
        console.log(response.data);
          if(response.data.error)
          {
              setLoginError(true);
              setErrorMsg(response.data.error);
              setVarificationBtnDisabled(false);
              
          }else{
            // console.log("dddddd");
              handleAfterLogin(response);
          }
      })
      .catch(error => {        
        setLoginError(true);
        setVerifyButtonText(t('lbl_webhook_verify'))
        setErrorMsg(t('lbl_webhook_msg_error_verify_code'));
      });
  };

  function handleAfterLogin(response)
  {
    const has2FA = getCookie("2FA_"+email);
    if (response.data.error === "UnAuthorised") { //Check username & password valid or not
      alert(t('lbl_invalid_username_password'));
    }
    else if(has2FA === "1" || response.data.token)
    {
      if(isRemember)
      {
          setCookie('2FA_'+email,"1");
      }
      changeLanguage(response.data.selectedLanguage);
      // document.getElementById("loginProgress").style.display = "none";
      
     
      if(response.data.permission_data)
      {
        localStorage.setItem('permissonData', JSON.stringify(response.data.permission_data));
        
      }
      if (response.data.token) {
        localStorage.setItem('admin_email',email);
        localStorage.setItem('is_active',response.data.is_active);
        setToken(response.data.token);
        
        const currentWebUrl =  window.location.href;  
        var localHostMode = "OFF";
        if(currentWebUrl == "https://web17.magicpaypos.com/" || currentWebUrl == "https://web17.magicpaypos.com" || currentWebUrl == "https://web17.magicpaypos.com/login"  || currentWebUrl == "https://web17.magicpaypos.com/login/"){
          localHostMode = "ON"; 
        } else {
          if(currentWebUrl == "http://localhost:3000/login"){
              localHostMode = "ON";
          } else if(currentWebUrl == "https://devapp.magicpaypos.com/login/" || currentWebUrl == "https://devapp.magicpaypos.com/login" || currentWebUrl == "https://devapp.magicpaypos.com/"  || currentWebUrl == "https://devapp.magicpaypos.com"){
              localHostMode = "ON";
          } else if(currentWebUrl == "https://billing.devapp.magicpaypos.com/login/" || currentWebUrl == "https://billing.devapp.magicpaypos.com/login" || currentWebUrl == "https://billing.devapp.magicpaypos.com/"  || currentWebUrl == "https://billing.devapp.magicpaypos.com"){
            localHostMode = "ON";
          } 
        }  
        
        const currentWebUrlArr = currentWebUrl.split(response.data.reactWebBaseUrl); 
        var prefixUrl;	
        prefixUrl = currentWebUrlArr[0]; 	
        prefixUrl = prefixUrl.replace("http://", "");	
        prefixUrl = prefixUrl.replace("https://", ""); 	
        prefixUrl = prefixUrl.replace("www.", ""); 

        if (response.data.role == "4") { //Check user was admin or merchant
          localStorage.setItem('group_id', response.data.group_id);
          localStorage.setItem('arg1', email);
          localStorage.setItem('arg2', password);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('role', response.data.role);
          if(localStorage.getItem("token") !== "token")	
          {	
            
            localStorage.setItem('admin_permission',JSON.stringify(response.data.admin_permission));                        

            if(localHostMode == "ON"){	
              window.location.href = "/admin/dashboard"; 	
            } else {	
              if(prefixUrl == response.data.adminWebVersion){	
                window.location.href = "/admin/dashboard";	
              } else { 	
                window.location.href = "https://"+response.data.adminWebVersion+response.data.reactWebBaseUrl+"/login/?arg1="+btoa(email)+"&arg2="+btoa(password);	
              }	
            }	
          }	
            
        } else {
         
          if(localHostMode == "ON"){
                localStorage.setItem('merchantToken', response.data.token);
                localStorage.setItem('role', 'merchant');
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('parent_id', response.data.parent_id);
                localStorage.setItem('location_id', response.data.location_id);           
                if(localStorage.getItem("merchantToken") !== null)
                {
                  window.location.href = "/dashboard"; 
                } 
          } else if(localHostMode == "OFF"){
            
          if(response.data.maxVersion == response.data.deviceVersion){
            if(currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login" || currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/"){
              localStorage.setItem('merchantToken', response.data.token);
              localStorage.setItem('role', 'merchant');
              localStorage.setItem('id', response.data.id);
              localStorage.setItem('parent_id', response.data.parent_id);
              localStorage.setItem('location_id', response.data.location_id);           
              if(localStorage.getItem("merchantToken") !== null)
              {
                window.location.href = "/dashboard";
              }   
            } else {
              window.location.href = "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/?arg1="+btoa(email)+"&arg2="+btoa(password); 
            }
            

          } else if((parseFloat(response.data.maxVersion) > parseFloat(response.data.deviceVersion))){ 
            if(currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login" || currentWebUrl == "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/"){
              localStorage.setItem('merchantToken', response.data.token);
              localStorage.setItem('role', 'merchant');
              localStorage.setItem('id', response.data.id);
              localStorage.setItem('parent_id', response.data.parent_id);
              localStorage.setItem('location_id', response.data.location_id);           
              if(localStorage.getItem("merchantToken") !== null)
              {
                window.location.href = "/dashboard";
              }   
            } else {
              window.location.href = "https://"+response.data.webVersion+response.data.reactWebBaseUrl+"/login/?arg1="+btoa(email)+"&arg2="+btoa(password); 
            }
            
          }   else {
            window.location.href = "https://"+response.data.reactWebBaseUrl+"/";  
          }
         }
        }

      }

    }else{
      // setLoginData(response.data);
      setIsVerificationStep(true);
      setLoginError(false);
      return;
    }
  }
  function handleSubmit(event) {
    event.preventDefault();
    document.getElementById("loginProgress").style.display = "block";
    const has2FA = getCookie("2FA_"+email);      
    const data = { email: email, password: password,"has2FA":(has2FA)?has2FA:"0", selectedLanguage:selectedLanguage };
    axios.post(window.apiUrl + 'login', data)
      .then(response => {
        if(email.length > 0){
          hideemail(email);
        }
        handleAfterLogin(response);
      }).catch(error => {
        console.log("-----ERROR START-----");  
        console.log(error);  
        document.getElementById("loginProgress").style.display = "none";
        if (error.response.data.activateError) { 
         setErrorMsg(error.response.data.activateError);
        } else {
          setErrorMsg(t('msg_Invalid_eMail_or_Password'));
        }
        setLoginError(true);
        return error;
      });

  }

  const [code, setCode] = useState(Array(6).fill('')); // Track each digit of the code
  const [isValid, setIsValid] = useState(true); // For validation feedback
  const [isRemember, setIsRemember] = useState(false); // For validation feedback

  function onPaste(event) {
    var pasted = event.clipboardData.getData("text/plain");
    pasted = pasted.split("");
    var valuearr = [];
    for(let i=0; i<6;i++)
    {
      if(pasted[i] !== undefined)
      {
        valuearr[i] = pasted[i];
      }else{
        valuearr[i] = "";
      }
      
    }
    setCode(valuearr);
    if(valuearr.length == 6){
      setVarificationBtnDisabled(false);
      document.getElementById(`otp-input-5`).focus();
      document.getElementById(`btnGreenDisabled`).focus();
      // document.getElementById(`otp-input-5`).focus();
    }
    
    // setCode(pasted.split("").slice(0, 6).concat(Array(6 - Math.min(pasted.length, 6)).fill("")));
    setIsValid(true);
  }
 
  const handleChange = (e, index) => {
    if (e.key === 'Backspace') {      
      if(e.target.value === '' && (index - 1) > -1)
      {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }      
      return;
    }

    let newCode = [...code];
    const newValue = e.target.value;
  
    // Only allow numbers
    if (/[^0-9]/.test(newValue)) {
      newCode[index] = ''; // Remove non-numeric input
    } else {
      newCode[index] = newValue;
    } 

    console.log("newValue="+newValue);
    console.log("newCode="+newCode); 
    setCode(newCode);
   console.log("AfterSetVal="+code);
    // Move to the next input field automatically if digit is entered
    if (newValue !== '' && index < 5) {
      console.log("regular call");
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  
    // Validate if all fields are filled
    
    setIsValid(newCode.every((digit) => digit !== ''));
    if(newCode.every((digit) => digit !== '')){      
      setVarificationBtnDisabled(false);
      varifyBtnRef.current.focus();
    }
    else{
      setVarificationBtnDisabled(true);
    }
  };
  
  

  const setCookie = (name, value) => {
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); 
    const expires = `expires=${date.toUTCString()}`;
    
    // Ensure the cookie is set with `path`, `expires`, `Secure` (for HTTPS), and `SameSite`
    document.cookie = `${name}=${value}; ${expires}; path=/login; Secure; SameSite=None`;
};

const getCookie = (name) => {
  const nameEq = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(nameEq) === 0) {
          return c.substring(nameEq.length, c.length);
      }
  }
  return '';
};

function hideemail(e) {
  var emailMain = e;
  var hiddenEmailMain = "";
  for (var i = 0; i < emailMain.length; i++) {
    if (i > 0 && i< emailMain.indexOf("@")-1 ) {
      hiddenEmailMain += "*";
    } else {
      hiddenEmailMain += emailMain[i];
    }
  }
  console.log("eeeeeeeeeeeeeeee "+hiddenEmailMain)
  setSecureEmail(hiddenEmailMain);
}

function handleTwoFaDialogClose (){
  setIsVerificationStep(false);
}

  return (   
    <div >
      <div>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={loginError} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={sucess}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert severity="success">{successMsg}</Alert>
              </Snackbar>
      </div> 
      <div id="LoginBoxMainSection">
      <Container id="loginBox" component="main" maxWidth="xs">
        
        <div style={{ display: (queryParams.get('arg1') !== null) ? "block" : "none" }}>
            <center>{t('lbl_please_wait')}</center>
        </div>
        <div style={{ display: (queryParams.get('arg1') !== null) ? "none" : "block" }}>
        <CssBaseline />
        {/* 
          Display error message
        */}

        <div className={classes.paper}>
          {/* Magicpay main logo */}
          <img src={LogoPath}></img>
          <Typography component="h1" variant="h5">
            {t('lbl_sign_in')}
          </Typography>
          <CircularProgress id="loginProgress" style={{ display: "none" }}></CircularProgress>

          {/**
             * Form for user login
         */}

          <form className={classes.form} onSubmit={handleSubmit}>
            {/* Email address textfield */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('lbl_email_address')}
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* password textfield */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('lbl_password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* sign in button */}
            <div id="divLoginBtn">
              <Button
                id="loginBtn"
                type="submit"
                fullWidth
                variant="contained"
                color="warning"
                className={classes.submit}
                disabled={!validateForm()}
              >
                {t('lbl_sign_in')}
              </Button>
            </div>
            {/* forgot password link  */}
            <Grid container>
              <Grid item xs>
                <div id="forgotPasswordDiv">
                  <Link id="forgotPassword" href="/forgot_password" variant="body2">
                    {t('lbl_forgot_password')}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
        
        {/* copyright and term and conditions */}
        <div id="copyRightDiv">
        <Box mt={8}>
          <Copyright />
        </Box>
        </div>
        </div>
          <Grid container>
              <Grid item xs>
              <div style={{marginTop:"15px"}}>
                  <div style={{display:"flex"}}>
                    {/* <i class="fa fa-language" aria-hidden="true" style={{fontSize:"20px"}}></i> */}
                    <span style={{ cursor: "pointer",display:"flex",fontWeight:"400" }} onClick={() => changeLanguage('en')}>EN <div class="languageFlag loginLanguageChangeSec" id="en"></div></span> &nbsp;| &nbsp; <span style={{ cursor: "pointer",display:"flex",fontWeight:"400" }} onClick={() => changeLanguage('es')}>ES <div class="languageFlag loginLanguageChangeSec" id="mx"></div></span>
                  </div>       
              </div>
              </Grid>
            </Grid>

                  {/* 2FA Verification Dialog Start */}

        <Dialog
          id="twoFADialog"
          open={isVerificationStep}          
          // fullWidth
          maxWidth={"xs"}
          onClose={() => {
            setIsVerificationStep(false);
          }}
          aria-labelledby="form-dialog-title"
        >

          <IconButton
                    id="twoFACloseIconBtn"
                    aria-label="close"
                    onClick={handleTwoFaDialogClose}
                    sx={(theme) => ({
                      position: 'absolute',
                      right: 13,
                      top: 13,
                      color: theme.palette.grey[500],
                    })}
                  >
                    <CloseIcon />
          </IconButton>

          <DialogContent>

              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Grid justifyContent="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                    id="twoFaMainBox"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <div style={{height:"100px",width:"100px",margin:"0 auto"}}>
                        <lottie-player src="/animEmail.json" background="transparent"  speed=".8"  autoplay>
                        </lottie-player>
                      </div>
                      <Typography variant="h4" id="twoFaDialogHeader" color="info.main">
                      {t('lbl_webhook_enter_your_code')}
                      </Typography>
                      <Typography id="twoFaDialogHeaderSecond" style={{fontSize:"20px"}} variant="body1" sx={{ marginBottom: 3 }}>
                      {t('lbl_webhook_enter_code_sent_to_your_email')} {secureEmail} .
                      </Typography>
                      <div id="twoFaInputBoxSection">

                      {/* Error message */}
                      {/* {!isValid && (
                        <Typography color="error" style={{marginBottom:"10px"}}>
                          {t('msg_webhook_enter_all_digits')}
                        </Typography>
                      )} */}
                  
                      <GridContainer container spacing={1} justifyContent="center">
                        
                      {code.map((digit, index) => (
                        <GridItem id="inputBoxGrid" xs={2} sm={2} md={2} item key={index}>
                          <TextField
                            id={`otp-input-${index}`}
                            variant="outlined"
                            value={digit}
                            onKeyDown={(e) => handleChange(e, index)}
                            onChange={(e) => handleChange(e, index)} 
                            onPaste={onPaste}
                            sx={{
                              width: '10px',
                              height: '40px',
                              textAlign: 'center',
                            }}
                            inputProps={{
                              maxLength: 1,
                              style: { textAlign: 'center' },
                              autoComplete:'off'
                            }}
                          />
                        </GridItem>
                      ))}

                      
                      </GridContainer>
                      <GridContainer style={{textAlign:"left"}}>
                          <GridItem xs={12} sm={12} md={12} id="rememberSection">
                              <Checkbox
                                    id="cbRememberAuth"
                                    value={isRemember}
                                    onChange={(e) => { setIsRemember(e.target.checked); }}
                                /><span id="rememberText">{t('lbl_webhook_remember')}</span>
                            </GridItem>
                      </GridContainer>
                      </div>

                      <Box>
                      <GridContainer container spacing={1} justifyContent="center">
                            <GridItem xs={6} sm={6} md={6} id="twoFaDialogButtonSectionFirst">
                                <Button
                                id="btnGreenDisabled"
                                  variant="contained"
                                  color="info"
                                  fullWidth
                                  ref={varifyBtnRef} 
                                  disabled={varificationBtnDisabled}
                                  onClick={handleVerificationSubmit}
                                >
                                  {verifyButtonText}
                                </Button>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} id="twoFaDialogButtonSectionSecond">
                                <Button
                                id="btnResendBlue"
                                  variant="contained"
                                  color="info"
                                  fullWidth
                                  disabled={resendBtnDisabled}
                                  onClick={resendVerificationCode}
                                >
                                  {t('lbl_webhook_resend')}
                                </Button>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem id="twoFaBottomSection" xs={12} sm={12} md={12}>
                            <Typography>
                              <div style={{textAlign:"center"}}><span id="twoFaDialogdidntReceiveText">{t('lbl_webhook_didnt_receive_email')}</span> <a onClick={sendCodeToSMS} style={{marginLeft:"5px",cursor:"pointer",color:"#3c4858",fontWeight:"500",whiteSpace:"nowrap"}}>{t('lbl_send_sms')}.</a></div>
                            </Typography>
                          </GridItem>
                        </GridContainer>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

          </DialogContent>
        </Dialog>

        {/* 2FA Verification Dialog End */}


      </Container>
      </div>

    </div>

  );
//  }  
  
}