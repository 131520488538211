/*
   @page table list page (merchant list)
*/

//Import require libraries
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "components/CustomButtons/Button.js";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import NotificationImportant from "@mui/icons-material/NotificationImportant";

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import { display, padding, width } from "@mui/system";
import { useTranslation } from 'react-i18next';

/*
  @const Page styles
*/
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

/*
  @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

/*
  @function merchant list page logic
*/
const useStyles = makeStyles(styles);

const options = {  
};
export default function TableList() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [tableData, setTableData] = useState(false);
  const [error, setError] = useState(false);
  const [sucess, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  
  const [filterMId, setFilterMId] = useState("");
  const [filterBusinessName, setFilterBusinessName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [batchTransDailog, setBatchTransDailog] = useState(false);
  const [notificationDailog, setNotificationDailog] = useState(false);
  const [sendToMerchantChkBox, setSendToMerchantChkBox] = useState(false);
  const [sendToOtherChkBox, setSendToOtherChkBox] = useState(false);
  const [otherEmails, setOtherEmails] = useState(['']);
  const [transData, setTransData] = useState(false);
 

  const getBatchTrans = (e, batchId, serial_number) => {
    setBatchTransDailog(true);
    e.preventDefault();
    const data = { batch_id: batchId, serial_number: serial_number };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
   
    axios.post(window.apiUrl + 'get_open_batch_trans', data, {
      headers: headers
    }).then(response => {
      console.log(response);
      var data = [];
      response.data.transData.forEach((eachTrans) => {
          
        let transTime = (eachTrans.device_created_at) ? moment(new Date(eachTrans.device_created_at.replace(" ", "T"))).format('MM/DD/YYYY HH:mm') : "";
        data.push([transTime, eachTrans.ecrref_num, <center>{(eachTrans.edc_type) ? eachTrans.edc_type : eachTrans.payment_method}</center>, <center>{eachTrans.auth_code}</center>, <div style={{ textAlign: 'center' }}>  {(eachTrans.transaction_type == "RETURN")?"-":""}{(eachTrans.ecrref_num) ? "$" : ""}  {(eachTrans.amount) ? eachTrans.amount: ""}</div>]);
        console.log("ddddddd---**");
      });
      setTransData(data);
      console.log(data);
    }).catch(error => {     
      return error;
    });
  }
  const hideRaw = (id) =>
  {
      
    const data = { id: id };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'hide_open_batch_rec', data, {
      headers: headers
    }).then(response => {
      setTableData(false);
      getMerchantList();
    }).catch(error => {   
      return error;
    });
  }
  const doLogin = (merchantId,locationId) => {
    const data = { merchant_id: merchantId };
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'login_as_merchant', data, {
      headers: headers
    }).then(response => {
      if (response.data.token) {
        const currentWebUrl =  window.location.href;  
        var localHostMode = "OFF";
        if(currentWebUrl == "https://web17.magicpaypos.com/" || currentWebUrl == "https://web17.magicpaypos.com" || currentWebUrl == "https://web17.magicpaypos.com/login"  || currentWebUrl == "https://web17.magicpaypos.com/login/"){
            localHostMode = "ON"; 
        } else {
            if(currentWebUrl == "http://localhost:3000/admin/table" || currentWebUrl == "http://localhost:3000/admin/table/"){
                localHostMode = "ON";
            } else if(currentWebUrl == "https://devapp.magicpaypos.com/admin/table" || currentWebUrl == "https://devapp.magicpaypos.com/admin/table/"){
                localHostMode = "ON";
            }
            else if(currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table" || currentWebUrl == "https://testnewsrv-web14.magicpaypos.com/admin/table/"){
                localHostMode = "ON";
            }
        }
        if(response.data.same_version === "0" && localHostMode == "OFF")
        { 
         
           window.open(response.data.react_url+'login/?arg1='+btoa(localStorage.getItem('arg1'))+'&arg2='+btoa(localStorage.getItem('arg2'))+'&arg3='+btoa(merchantId), '_blank');
        }
       else
        {      
          console.log("in elseeeee");  
          localStorage.setItem('merchantToken', response.data.token);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('parent_id', merchantId);
          localStorage.setItem('location_id',locationId);
          
          window.open('/dashboard', '_blank');
          
        }
  
      }
    }).catch(error => {   
      return error;
    });
  
  }
  
  /*
    @Function get merchant list
  */  
  const getMerchantList = () => {
    const data = { filterBusinessName: filterBusinessName,filterEmail:filterEmail,filterMId:filterMId,group_id: localStorage.getItem('group_id')};
    const headers = {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl+'get_open_batch', data, {
      headers: headers
    }).then(response => {
      const userArr = [];
      response.data.forEach((eachOpenBatch) => {
        var substr = eachOpenBatch.business_name;
        if(eachOpenBatch.business_name.length > 24)
        {
          substr = eachOpenBatch.business_name.substring(0, 24)+"..."
        }
        userArr.push([eachOpenBatch.user_id,eachOpenBatch.mid,<span class="spText"><span>{substr}</span><span class="tooltiptext">{eachOpenBatch.business_name}</span></span>,eachOpenBatch.serial_number,<span  class="spText"><span>{eachOpenBatch.nick_name}</span><span class="tooltiptext">{eachOpenBatch.nick_name}</span></span>,eachOpenBatch.version,eachOpenBatch.openBatchDays, eachOpenBatch.created_at,eachOpenBatch.last_login,"$"+eachOpenBatch.amount,<Button id="viewOpenBatchIcon" style={{ width:'100px', color: 'black', background: '#FFFFFF' }} onClick={(e) => { getBatchTrans(e, 0, eachOpenBatch.serial_number) }}><RemoveRedEyeOutlinedIcon></RemoveRedEyeOutlinedIcon></Button>,<Button id="LoginIcon" style={{ width:'100px', color: 'black', background: '#FFFFFF' }} onClick={(e) => { doLogin(eachOpenBatch.user_id,eachOpenBatch.location_id) }}><LoginOutlinedIcon></LoginOutlinedIcon></Button>,<Button onClick={(e)=>{hideRaw(eachOpenBatch.id);}}><VisibilityOffIcon></VisibilityOffIcon></Button>]);
      });     
      console.log(userArr);
      setTableData(userArr);
    }).catch(error => {     
      return error;
    });
  }

 
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setSuccess(false);
    setSuccessMsg("");
  };

  useEffect(() => {
    getMerchantList();
    getAdminSetting();
  }, []);

  const columns = [
    {
     name: "User ID",
     label: "ID",
     options: {
      filter: true,
      sort: false,
     }
    },
    ,
    {
     name: "Business Name",
     label: "Business Name",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "Serial Number",
     label: "serial_number",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
      name: "Amount",
      label: "amount",
      options: {
       filter: true,
       sort: false,
      }
     },
    {
      name: "Created at",
      label: "Created at",
      options: {
       filter: true,
       sort: false,
      }
     },
     
   ];
 const deleteOthersEmail = (index) =>
 {
  
    const updatedEmails = [...otherEmails];
    updatedEmails.splice(index,1); // Update the value at the given index
    setOtherEmails(updatedEmails);
 }
   const handleNotificationClick = () => 
   {
     setNotificationDailog(true);
   }
   const handleFieldChange = (index, value) => {
    const updatedEmails = [...otherEmails];
    updatedEmails[index] = value; // Update the value at the given index
    setOtherEmails(updatedEmails);
  };
  const getAdminSetting = ()=>{

    
    const data = { };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'get_admin_settings', data, {
      headers: headers
    }).then(response => { 

        console.log(response);
        setSendToMerchantChkBox(response.data.send_open_batch_mail_merchant);
        setSendToOtherChkBox(response.data.send_open_batch_mail_other);
        setOtherEmails(response.data.other_mail_address.split(","));
    }).catch(error => {     
      return error;
    });
  }
  const handleAddField = () => {
    setOtherEmails([...otherEmails, '']); // Add a new empty field
  };
  const saveNotificationSetting = () =>
  {
    
    console.log(otherEmails);
    var emailArr = otherEmails.filter(function (el) {
      return el != "";
    });
    const data = { send_open_batch_mail_merchant:sendToMerchantChkBox,send_open_batch_mail_other:sendToOtherChkBox,other_mail_address: emailArr.join(",") };
    const headers =
    {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    axios.post(window.apiUrl + 'save_admin_settings', data, {
      headers: headers
    }).then(response => { 
      setSuccess(true);
        setSuccessMsg(t('lbl_settings_save_successfully'));
        setNotificationDailog(false);
        setTableData(false);
        getAdminSetting();
        getMerchantList();
    }).catch(error => {     
      return error;
    });
  } 
var permissionData = JSON.parse(localStorage.getItem("admin_permission"));  
if(permissionData.access_open_batch == "0")
{
  
    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
            <CardHeader className="cardheader" color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_open_batch_list')}</h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                  <GridItem xs={4} sm={12} md={12}>
                  <center>
                    <h3>
                    <div>{t('msg_you_do_not_have_permission')}</div> 
                    <div>{t('msg_please_contact_an_administrator')}</div>
                    <div>{t('msg_thank_you')}</div>
                    </h3>
  
                  </center>
                  <div id="transitInfo"></div>
                  </GridItem>
              </GridContainer>
            </CardBody>

          </Card>
        </GridItem>
      </GridContainer>);
  
}

  if (!tableData) { //Check if table data not come than display loading dialog
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          {/* 
            Display error message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>{t('lbl_open_batch_list')}</h4>
              <p className={classes.cardCategoryWhite}>
              </p>
            </CardHeader>
            <CardBody>
              <center>
                <h3>{t('msg_loading')}</h3>
                <CircularProgress id="loginProgress" ></CircularProgress>
              </center>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>);

  }
  return (
    <GridContainer>
       <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={error} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>

          {/* 
            Display success message
          */}
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
        <Dialog
                id="dialoggggggg"
                  open={batchTransDailog}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  style={{ padding: "0px" }}
                >
        <DialogTitle id="alert-dialog-slide-title">{t('lbl_batch_transactions_list')}</DialogTitle>
        {
          (transData) ? <Table
            tableHeaderColor="default"
            tableHead={[t('lbl_trans_time'), t('lbl_ecr_ref'), <center>{t('lbl_payment_method')}</center>, <center>{t('lbl_authcode')}</center>, <center>{t('lbl_amount')}</center>]}
            tableData={transData}
          /> : <center><CircularProgress /></center>
        }

        <DialogActions>
          <Button id="btnGreyCancel"  onClick={(e) => { setBatchTransDailog(false); setTransData(false) }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
            {t('lbl_close')}
          </Button>

        </DialogActions>
      </Dialog>


      {/* Notification popup start# */}
      <Dialog
                id="notificationDailog"
                  open={notificationDailog}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
        <DialogTitle id="alert-dialog-slide-title">{t('lbl_open_batch_notifications')}</DialogTitle>
            <div><Checkbox                                
              checked={sendToMerchantChkBox}
              onChange={(e) => { setSendToMerchantChkBox(e.target.checked);}}
            />{t('lbl_send_to_merchants')}
            </div>
            <div> <Checkbox                                
              checked={sendToOtherChkBox}
              onChange={(e) => { setSendToOtherChkBox(e.target.checked);}}
            />{t('lbl_send_to_others')} <span style={{display:(sendToOtherChkBox)?"":"none"}}><Button id="addNewEmailBtn" onClick={handleAddField}>+</Button></span></div>
            <hr id="lineForAddEmail"></hr>
            <div id="otherAllEmailDiv" style={{display:(sendToOtherChkBox)?"block":"none",overflowX:"hidden"}}>
           
               {
                  Object.entries(otherEmails).map(([index,email]) => (
                    <GridContainer style={{paddingLeft:"15px"}}>
                      < GridItem xs={10} sm={10} md={10} style={{marginTop:"15px",marginBottom:"15px",width:"100%"}}>
                        <TextField
                            key={index}                            
                            style={{width:"100%"}}
                            id={`email-${index}`}
                            variant="outlined"                    
                            margin="full"
                            label={t('lbl_email')}
                            value = { email }
                            onChange={(e) => handleFieldChange(index, e.target.value)}
                          />     
                     </GridItem>
                     < GridItem id="deleteEmailBtnSection" xs={2} sm={2} md={2} style={{marginTop:"28px",marginBottom:"15px",width:"100%"}}>  <DeleteForeverIcon onClick={(e)=>{deleteOthersEmail(index)}} style={{color:"#CF5454",cursor:"pointer"}}></DeleteForeverIcon>  </GridItem>
                  </GridContainer>
                  ))
                }
                   
                      
              
            </div>
        <DialogActions>
            <Button id="btnGreyCancel" onClick={() => { setNotificationDailog(false); }} color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF' }}>
              {t('lbl_cancel_smallcase')}
            </Button>
            <Button id="btnGreenAdd" onClick={(e) => { saveNotificationSetting() }}>
              {t('lbl_save_smallcase')}
            </Button>

        </DialogActions>
      </Dialog>
      {/* Notification popup end# */}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>{t('lbl_open_batch_list')}</h4>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
            <GridItem xs={12} sm={12} md={3}>   
              <Button id="notificationButton" onClick={handleNotificationClick}><NotificationImportant></NotificationImportant> </Button>                
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Amount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label={t('lbl_mid')}
                        value = { filterMId }
                        onChange={(e) => {setFilterMId(e.target.value)}}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Amount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label={t('lbl_business_name')}
                        value = {filterBusinessName}
                        onChange={(e) => { setFilterBusinessName(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                    {/* <div style={{ height: "25px" }}>&nbsp;</div> */}
                    <FormControl style={{ width: "100%",display:"none" }}>
                      {/* Amount from textfield */}
                      <TextField
                        id="outlined-basic"
                        variant="outlined"                    
                        margin="full"
                        label={t('lbl_email')}
                        value = { filterEmail}
                        onChange={(e) => { setFilterEmail(e.target.value) }}                    
                      />
                    </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <center>
                {/* <div style={{ height: "25px",display:"none" }}>&nbsp;</div> */}
                  <FormControl style={{ width: '100%',marginTop:"-5px",display:"none" }} className={classes.popupHalfWidth} xs={12} sm={12} md={3}>
                    <Button onClick = {getMerchantList}  variant="contained" color="primary" style={{ backgroundColor: '#ffa726', color: '#FFFFFF', width: '100%', height: '55px' }} className={classes.button}>
                      {t('lbl_search')}
                    </Button>
                  </FormControl>
                </center>
              </GridItem>
            </GridContainer>
            <div id="divBatchRecordTable">
            <Table
              tableHeadercolor="default"
              tableHead={[t('lbl_id'),t('lbl_mid'),t('lbl_business_name'), t('lbl_serial')+" #",t('lbl_nickname'),t('lbl_version'),t('lbl_status'), t('lbl_last_batch'),t('lbl_last_login'),t('lbl_amount'),t('lbl_view'),t('lbl_login'),t('lbl_hide')]}
              tableData={tableData}
            />
            </div>
            {/* <MUIDataTable
              id="newdatatable"
              // title={"Employee List"}
              data={tableData}
              columns={columns}
              options={options}
            /> */}
          </CardBody>
        </Card>

      </GridItem>
    </GridContainer>
  );
}
