/*
  @page Forgot password
*/

//Import require libraries
import React,{useState } from "react";
import axios from 'axios';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import LogoPath from "assets/img/Logo.png";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

/*
  @function for magicpay main logo
*/
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t('lbl_copyright')} © {" "}
      <Link color="inherit" href="https://magicpay.net/">
       MagicPay Merchant Services LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

/*
  @function Display alert for error message
*/
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

/*
  @const Page styles
*/
const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    // marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
}));

/*
  @function Forgot password page logic
*/
export default function LoginPage() {
 const {t,i18n} = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [loginError, setLoginError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [sucess, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  
/*
  @function email address validation
*/
  function validateForm() {

    // return  ;
  }
  
  /*
    @function For close error popup 
  */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setLoginError(false);
    setSuccess(false);
  };

  const checkEmailChange = (e) => {
    if(e.length > 0){
      setDisableButton(false)
    }
    else
    {
      setDisableButton(true)
    }
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);  
    const currentPage = location.pathname;      
    const roloadPagesArr = ['/admin/table', '/admin/device_manager','/admin/incorrect_billing_amount_Kushki','/admin/admin_employees'];
    if( roloadPagesArr.includes(currentPage)){    
      window.location.reload();
    }
  };

  /*
    @function For forgot password form submit 
  */
  function handleSubmit(event) {
    setDisableButton(true);
    event.preventDefault();
    document.getElementById("loginProgress").style.display = "block";
    const data = { email:email};
    data.parent_id = localStorage.getItem('parent_id');
    axios.post(window.apiUrl+'forgot_password', data)
        .then(response => {
          setDisableButton(false);
          document.getElementById("loginProgress").style.display = "none";
          setSuccess(true);
          setSuccessMsg(t('msg_email_has_been_sent_to_your_email_address'));         
          
        }) .catch(error => {
          setDisableButton(false);
          document.getElementById("loginProgress").style.display = "none";
          if(error.response.status === 401)
          {
            if(error.response.data.activateError)
            {
              setErrorMsg(error.response.data.activateError);
            }else
            {
              setErrorMsg(t('msg_Invalid_eMail_or_Password'));
            }
            setLoginError(true);
          }  
          if(error.response.status === 500)
          {
            setErrorMsg(t('msg_Email_address_not_found'));
            setLoginError(true);
          }        
          return error;
        });
                
  }
  return (
    <div>
      <div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={loginError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={sucess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>
      </div>
      <div id="forgotPasswordMainBox">
      <Container id="forgotPasswordBox" style={{width:"500px !important"}} component="main" maxWidth="xs">
        <CssBaseline />

        <div className={classes.paper}>
          <img src={LogoPath}></img>
          <Typography style={{margin:"10px 0px"}} component="h1" variant="h5">
            {t('txt_forgot_password')}
          </Typography>
          <CircularProgress id="loginProgress" style={{display:"none"}}></CircularProgress>
          
          {/* 
            * Display forgot password form
          */}
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('lbl_email_address')}
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {setEmail(e.target.value);checkEmailChange(e.target.value);}}
            />          
            <div id="divLoginBtn" style={{marginTop:"30px"}}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="warning"
                className={classes.submit}
                disabled={disableButton}
              >
                {t('txt_forgot_password')}
              </Button>
            </div>
          </form>
        </div>

        {/* Display copyright */}
        <div id="copyRightDiv">
        <Box mt={8}>
          <Copyright />
        </Box>
        </div>
        <Grid container>
              <Grid item xs>
              <div style={{marginTop:"20px"}}>
                  <div style={{display:"flex"}}>
                    {/* <i class="fa fa-language" aria-hidden="true" style={{fontSize:"20px"}}></i> */}
                    <span style={{ cursor: "pointer",display:"flex",fontWeight:"400" }} onClick={() => changeLanguage('en')}>EN <div class="languageFlag loginLanguageChangeSec" id="en"></div></span> &nbsp;| &nbsp; <span style={{ cursor: "pointer",display:"flex",fontWeight:"400" }} onClick={() => changeLanguage('es')}>ES <div class="languageFlag loginLanguageChangeSec" id="mx"></div></span>
                  </div>       
              </div>
              </Grid>
            </Grid>
      </Container>
      </div>

    </div>
  );
}