import React, { useState, useEffect } from 'react';
import { Select, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PhoneInput = ({ initialPhoneNumber, initialCountryCode, onPhoneChange, onCountryChange }) => {
  const { t } = useTranslation();
  const countries = [
    { code: 'us', name: 'United States', flag: '/flagIcons/us.png', dialCode: '+1' },
    { code: 'ca', name: 'Canada', flag: '/flagIcons/ca.png', dialCode: '+1' },
    { code: 'mx', name: 'Mexico', flag: '/flagIcons/mx.png', dialCode: '+52' },
    { code: 'pr', name: 'Puerto Rico', flag: '/flagIcons/pr.png', dialCode: '+1' },
    { code: 'vi', name: 'U.S. Virgin Islands', flag: '/flagIcons/vi.png', dialCode: '+1' },
    // Add more countries as needed
  ];

 

  // This function formats the phone number
  const formatPhoneNumber = (number) => {
    if(number)
    {
      let input = number.replace(/\D/g, ''); // Remove non-numeric characters
      const size = input.length;
  
      if (size > 0) {
        input = `(${input}`;
      }
      if (size > 3) {
        input = `${input.slice(0, 4)}) ${input.slice(4, 11)}`;
      }
      if (size > 6) {
        input = `${input.slice(0, 9)}-${input.slice(9)}`;
      }  
      return input;
    }else
    {
      return "";
    }
    
  };

  const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(initialPhoneNumber || ''));
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((country) => country.code === initialCountryCode) || countries[0]
  );

  useEffect(() => {
    setPhoneNumber(formatPhoneNumber(initialPhoneNumber));
  }, [initialPhoneNumber]);

  useEffect(() => {
    if (initialCountryCode !== selectedCountry.code) {
      const country = countries.find((country) => country.code === initialCountryCode);
      setSelectedCountry(country || countries[0]);
    }
  }, [initialCountryCode]);

  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.code === e.target.value);
    setSelectedCountry(country);
    if (onCountryChange) onCountryChange(country);
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
    if (onPhoneChange) onPhoneChange(formattedPhone.replace(/\D/g, ''));
  };

  return (
    <div className="phone-input-container">
      <div id="phoneFlagSection" className="country-selector">
        <div id="flagDiv">
          <Select
            labelId="country-select-label"
            variant="outlined"
            value={selectedCountry.code}
            onChange={handleCountryChange}
            renderValue={(selectedCoun) => {
              const country = countries.find((c) => c.code === selectedCoun);
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={country.flag}
                    alt={country.name}
                    style={{ width: 24, height: 16, marginRight: 10 }}
                  />
                  {country.dialCode}
                </div>
              );
            }}
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: 24, height: 16, marginRight: 10 }}
                />
                {country.dialCode}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div id="phoneFlagSectionTwo">
        <TextField
          margin="full"
          variant="outlined"
          type="tel"
          label={t('lbl_phone_number')}
          id="phoneField"
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
