import React from "react";
import ReactDOM from "react-dom/client";  // Import from react-dom/client
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import Merchant from "layouts/Merchant.js";
import Login from "layouts/Login.js";
import ForgotPassword from "merchantViews/ForgotPassword/ForgotPassword.js";

// import RTL from "layouts/RTL.js";  // If needed, uncomment

import "assets/css/material-dashboard-react.css?v=1.10.0";
import "assets/css/media-query.css";
import './i18n';
// import mroutes from "./merchantRoutes.js";

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '0b854b6f-67f1-418a-9847-d4f359300331',
    clientToken: 'pubd3460a194cf231bad8f4e7eb4aaffadb',
    site: 'us5.datadoghq.com',
    service: 'magicpay-pos-dashboard',
    env: 'Dev_MagicPayPOS',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

window.domainUrl = "https://apidev.devapp.magicpaypos.com/";
window.apiUrl = window.domainUrl + "api/";
window.tsepHandler = (eventType, event) => {
  if (eventType === "TokenEvent") {
    window.eventData = event;
    window.eventTypeData = eventType;
  }
};

// const dynamicRoute = mroutes.map((prop, key) => {
//   return <Route path={prop.path} element={prop.component} key={key} />;
// });

// Using createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById("root"));  // Create root using createRoot
root.render(
  <>
  
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/admin/*" element={<Admin />} />
      <Route path="/*" element={<Merchant />} />

    </Routes>
   
  </BrowserRouter>
  </>
);
